@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.IntroductionPopup {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba($green, .5);
	user-select: none;
	&--show {@include flex('center','center');}
}

.IntroductionPopup-body {
	position: relative;
	width: 70em;
	padding: 2em;
	overflow: hidden;
	background-color: transparent;
	background-image: url('../../assets/images/background-popup.png');
	background-size: 100% 100%;
	background-position: center center;
  background-repeat: no-repeat;
}

.IntroductionPopup-closeBtn {
	position: absolute;
	right: 1.75em;
	top: 1.75em;
	width: 3.0em;
	height: 3.0em;
	background-image: url('../../assets/images/icon-close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	&:hover {@include scale(1.01);}
}