@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Resources {
	width: 100%;
	height: 100%;
	background-color: $beige;
	text-align: left;
	@include no-select();
}



.Resources-header {
	padding: 8.625em 10em 0 10em;
	text-align: left;
}

.Resources-text {
	font-size: 1.875em;
	h2 {
		font-size: 1.833em;
		font-family: Antropos;
		color: $green;
		margin-top: 0;
	}
	a {
		color: black;
		text-decoration: underline;
		&:visited {color: black;}
	}
}

.Resources-body {
	// position: absolute;
	// left: 0;
	// bottom: 2em;
	// width: 100%;
	// height: calc(100% - 25em);
	// text-align: left;
	// overflow: auto;
	margin-top: 3em;
	padding: 0 10em 2em 10em;
}


.Resources-pdf {
	display: inline-block;
	vertical-align: top;
	margin: 0.5em;
	text-align: center;
	a {
		display: inline-block;
		height: 15.554em;
		img {
			border: 0.1em solid $green;
			width: 11em;
			
		}
	}
	&--landscape {
		a img {
			width: 22em;
		}
	}
}

.Resources-pdfTitle,
.Resources-pdfSubTitle {
	overflow: hidden;
	white-space: nowrap;
	color: #323232;
	font-weight: bold;
}
.Resources-pdfSubTitle {
	font-weight: normal;
	margin-bottom: 1em;
}
