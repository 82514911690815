@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ActivityDescription {
  position: absolute;
	width: 100%;
	height: 100%; 
	top: 0;
	left: 0;
	z-index: 5;
	background-color: $beige;
	user-select: none;
}

.ActivityDescription-header {
	width: 100%;
	height: 12em;
}

.ActivityDescription-title {
	font-weight: 600;
	padding-top: 1.5em;
	padding-left: 3.5em;
	font-size: 3.25em;
	text-align: left;
	font-family: Antropos;
	color: $green;
}

.ActivityDescription-icons {
	position: absolute;
	top: 1.5em;
	right: 1em;
	text-align: right;
}

.ActivityDescription-body {
	position: relative;
	width: 100%;
	height: calc(100% - 14em);
	overflow: auto;
	padding: 0 10em;
	img {
		width: 100%;
	}
	&::-webkit-scrollbar{display:none;}
}

.ActivityDescription-box {
	position: relative;
	font-size: 1.6em;
	line-height: 1.5;
	padding: 1em 1.5em;
	background-color: white;
	text-align: left;
	margin: 0.5em 0 2em 0;
	h3 {
		color: $green;
		font-family: Antropos;
		font-size: 1.3em;
		padding-bottom: 1em;
		margin-top: 0em;
	}
}
.ActivityDescription-boxText {
	position: relative;
}
.ActivityDescription-tracks {
	position: absolute;
	font-size: 1.5em;
	top: 1.2em;
	right: 1em;
	width: 4em;
	height: 4em;
	background-image: url('../../assets/images/background-tracks.svg');
	background-repeat: no-repeat;
	background-size: contain;
	@include opacity(0.5);
}

.ActivityDescription-sectionContainer {
	position: relative;
	padding: 0.5em 0em 1em 1em;
	margin-top: 0em;
	border-bottom: 0.2em solid darken($beige, 8%);
}

.ActivityDescription-sectionHeader{
	position: relative;
	font-weight: 600;
	z-index: 1;
	color: $green;
	font-family: Antropos;
	font-size: 2.6em;
	padding: 0.5em 0em;
	text-align: left;
	background-image: url('../../assets/images/icon-arrow-down.svg');
	background-repeat: no-repeat;
	background-size: 1.5em auto;
	background-position: top 0.5em right 1.5em;
	&--open {
		background-image: url('../../assets/images/icon-arrow-up.svg');
	}
}

.ActivityDescription-sectionBody {
	display: none;
	height: 0;
	text-align: left;
	font-size: 1.6em;
	&--open {
		height: auto;
		display: block;		
	}
	> div {
		p {
			margin-top: 0em;
		}
		ul {
			list-style: none;
			padding-left: 1em;
			margin-bottom: 0.5em;
		}
		li {
			font-size: 1em;
			padding-top: 0.5em;
			position: relative;
			// padding-left: 1em;
			&:before{
				content: '';
				position: absolute;
				background-image: url('../../assets/images/icon-menu-bullet.svg');
				width: 1.2em;
				height: 1.2em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
				left: -1.4em;
				top: 0.6em;
			}
		}
	}
}

.ActivityDescription-descriptionReasoning {
	text-align: left;
	font-size: 1.6em;
	padding-top: 1em;
	padding-left: 1em;
	h3 {
		margin: 0em;
		font-size: 1.25em;
		font-weight: 600;
	}
	p {
		margin-top: 0;
	}
	> div {
		ul {
			list-style: none;
			padding-left: 1em;
		}
		li {
			font-size: 1em;
			padding-top: 0.5em;
			position: relative;
			margin-bottom: 0.5em;
			&:before{
				content: '';
				position: absolute;
				left: -1.4em;
				top: 0.6em;
				background-image: url('../../assets/images/icon-menu-bullet.svg');
				width: 1.2em;
				height: 1.2em;
				background-size: contain;
				background-repeat: no-repeat;
				background-position: center center;
			}
		}
	}
}