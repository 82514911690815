/* Eye blink  */
@-webkit-keyframes eye-blink {
	0% {@include opacity(0);}
	14.999% {@include opacity(0);}
	15% {@include opacity(1);}
	15.999% {@include opacity(1);}
	16% {@include opacity(0);}
	63.999% {@include opacity(0);}
	64% {@include opacity(1);}
	64.999% {@include opacity(1);}
	65% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes eye-blink {
	0% {@include opacity(0);}
	14.999% {@include opacity(0);}
	15% {@include opacity(1);}
	15.999% {@include opacity(1);}
	16% {@include opacity(0);}
	63.999% {@include opacity(0);}
	64% {@include opacity(1);}
	64.999% {@include opacity(1);}
	65% {@include opacity(0);}
	100% {@include opacity(0);}
}

/* Birds */
@-webkit-keyframes bird1 {
	0% {@include rotate(0deg);}
	0.2% {@include rotate(-5deg);}
	0.4% {@include rotate(0deg);}
	31% {@include rotate(0deg);}
	31.2% {@include rotate(-5deg);}
	31.4% {@include rotate(0deg);}
	53% {@include rotate(0deg);}
	53.2% {@include rotate(-5deg);}
	53.4% {@include rotate(0deg);}
	82% {@include rotate(0deg);}
	82.2% {@include rotate(-5deg);}
	82.4% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes bird1 {
	0% {@include rotate(0deg);}
	0.2% {@include rotate(-5deg);}
	0.4% {@include rotate(0deg);}
	31% {@include rotate(0deg);}
	31.2% {@include rotate(-5deg);}
	31.4% {@include rotate(0deg);}
	53% {@include rotate(0deg);}
	53.2% {@include rotate(-5deg);}
	53.4% {@include rotate(0deg);}
	82% {@include rotate(0deg);}
	82.2% {@include rotate(-5deg);}
	82.4% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes bird2 {
	0% {@include rotate(0deg);}
	20% {@include rotate(0deg);}
	20.2% {@include rotate(-5deg);}
	20.4% {@include rotate(0deg);}
	71% {@include rotate(0deg);}
	71.2% {@include rotate(-5deg);}
	71.4% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes bird2 {
	0% {@include rotate(0deg);}
	20% {@include rotate(0deg);}
	20.2% {@include rotate(-5deg);}
	20.4% {@include rotate(0deg);}
	71% {@include rotate(0deg);}
	71.2% {@include rotate(-5deg);}
	71.4% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

/* Fire glow */
@-webkit-keyframes glow {
	0% {@include scale(1);}
	3% {@include scale(0.9);}
	8% {@include scale(1);}
	15% {@include scale(0.9);}
	17% {@include scale(1);}
	22% {@include scale(0.9);}
	29% {@include scale(1);}
	32% {@include scale(1.1);}
	37% {@include scale(1.0);}
	44% {@include scale(1.1);}
	48% {@include scale(1.0);}
	55% {@include scale(1.1);}
	60% {@include scale(1.0);}
	63% {@include scale(0.95);}
	70% {@include scale(1.0);}
	77% {@include scale(0.95);}
	80% {@include scale(0.80);}
	82% {@include scale(0.85);}
	88% {@include scale(0.95);}
	93% {@include scale(0.9);}
	100% {@include scale(1);}
}
@keyframes glow {
	0% {@include scale(1);}
	3% {@include scale(0.9);}
	8% {@include scale(1);}
	15% {@include scale(0.9);}
	17% {@include scale(1);}
	22% {@include scale(0.9);}
	29% {@include scale(1);}
	32% {@include scale(1.1);}
	37% {@include scale(1.0);}
	44% {@include scale(1.1);}
	48% {@include scale(1.0);}
	55% {@include scale(1.1);}
	60% {@include scale(1.0);}
	63% {@include scale(0.95);}
	70% {@include scale(1.0);}
	77% {@include scale(0.95);}
	80% {@include scale(0.80);}
	82% {@include scale(0.85);}
	88% {@include scale(0.95);}
	93% {@include scale(0.9);}
	100% {@include scale(1);}
}

/* Character glow */
@-webkit-keyframes character-glow-dark {
	0% {@include opacity(0);}
	3% {@include opacity(1);}
	8% {@include opacity(0);}
	15% {@include opacity(1);}
	17% {@include opacity(0);}
	22% {@include opacity(1);}
	29% {@include opacity(0);}	
	30% {@include opacity(0);}
	80% {@include opacity(1);}
	88% {@include opacity(0);}
	93% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes character-glow-dark {
	0% {@include opacity(0);}
	3% {@include opacity(1);}
	8% {@include opacity(0);}
	15% {@include opacity(1);}
	17% {@include opacity(0);}
	22% {@include opacity(1);}
	29% {@include opacity(0);}	
	30% {@include opacity(0);}
	80% {@include opacity(1);}
	88% {@include opacity(0);}
	93% {@include opacity(1);}
	100% {@include opacity(0);}
}
@-webkit-keyframes character-glow-bright {
	0% {@include opacity(0);}
	30% {@include opacity(0);}
	32% {@include opacity(1);}
	37% {@include opacity(0);} 
	44% {@include opacity(1);}
	48% {@include opacity(0);}
	55% {@include opacity(1);}
	60% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes character-glow-bright {
	0% {@include opacity(0);}
	30% {@include opacity(0);}
	32% {@include opacity(1);}
	37% {@include opacity(0);} 
	44% {@include opacity(1);}
	48% {@include opacity(0);}
	55% {@include opacity(1);}
	60% {@include opacity(0);}
	100% {@include opacity(0);}
}

/* Sun beams */
@keyframes sunbeam-1 {
	0% {@include opacity(1);}
	33% {@include opacity(0);}
	60% {@include opacity(1);}
}
@keyframes sunbeam-2 {
	0% {@include opacity(0);}
	20% {@include opacity(1);}
	45% {@include opacity(0);}
	70% {@include opacity(1);}
	100% {@include opacity(0);}
}
@keyframes sunbeam-3 {
	0% {@include opacity(0);}
	40% {@include opacity(1);}
	60% {@include opacity(0);}
	80% {@include opacity(1);}
	100% {@include opacity(0);}
}

/* Moon beams */
@keyframes moonbeam-1 {
	0% {@include opacity(0.5);}
	33% {@include opacity(0);}
	60% {@include opacity(0.5);}
}
@keyframes moonbeam-2 {
	0% {@include opacity(0);}
	20% {@include opacity(0.5);}
	45% {@include opacity(0);}
	70% {@include opacity(0.5);}
	100% {@include opacity(0);}
}
@keyframes moonbeam-3 {
	0% {@include opacity(0);}
	40% {@include opacity(0.5);}
	60% {@include opacity(0);}
	80% {@include opacity(0.5);}
	100% {@include opacity(0);}
}

@-webkit-keyframes sunbeamTranslate {
	0% {@include translate3d(3em, -2em, 0);}
	50% {@include translate3d(-2em, -2em, 0);}
	100% {@include translate3d(3em, -2em, 0);}
}
@keyframes sunbeamTranslate {
	0% {@include translate3d(3em, -2em, 0);}
	50% {@include translate3d(-2em, -2em, 0);}
	100% {@include translate3d(3em, -2em, 0);}
}
@-webkit-keyframes sunbeamTranslate2 {
	0% {@include translate3d(-3em, -2em, 0);}
	50% {@include translate3d(3em, -2em, 0);}
	100% {@include translate3d(-3em, -2em, 0);}
}
@keyframes sunbeamTranslate2 {
	0% {@include translate3d(-3em, -2em, 0);}
	50% {@include translate3d(3em, -2em, 0);}
	100% {@include translate3d(-3em, -2em, 0);}
}


/* Grass */
@-webkit-keyframes grass2 {
	0% {@include rotate(0deg);}
	19% {@include rotate(-5deg);}
	46% {@include rotate(11deg);}
	65% {@include rotate(-2deg);}
	70% {@include rotate(-3deg);}
	90% {@include rotate(2deg);}
	100% {@include rotate(0deg);}
}
@keyframes grass2 {
	0% {@include rotate(0deg);}
	19% {@include rotate(-5deg);}
	46% {@include rotate(11deg);}
	65% {@include rotate(-2deg);}
	70% {@include rotate(-3deg);}
	90% {@include rotate(2deg);}
	100% {@include rotate(0deg);}
}
@-webkit-keyframes grass3 {
	0% {@include rotate(10deg);}
	20% {@include rotate(0deg);}
	44% {@include rotate(5deg);}
	70% {@include rotate(1deg);}	
	100% {@include rotate(10deg);}
}
@keyframes grass3 {
	0% {@include rotate(10deg);}
	20% {@include rotate(0deg);}
	44% {@include rotate(5deg);}
	70% {@include rotate(1deg);}	
	100% {@include rotate(10deg);}
}
@-webkit-keyframes grass1 {
	0% {@include rotate(5deg);}
	20% {@include rotate(-5deg);}
	40% {@include rotate(8deg);}
	44% {@include rotate(8.2deg);}
	70% {@include rotate(-1deg);}
	100% {@include rotate(5deg);}
}
@keyframes grass1 {
	0% {@include rotate(5deg);}
	20% {@include rotate(-5deg);}
	40% {@include rotate(8deg);}
	44% {@include rotate(8.2deg);}
	70% {@include rotate(-1deg);}
	100% {@include rotate(5deg);}
}