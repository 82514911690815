@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Stories {
	position: relative;
	height: 100%;
	width: 100%;
	background-image: url('../../assets/images/background-forest2.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center bottom;
	user-select: none;
	padding: 8.625em 12.8125em 0 12.8125em;
}

.Stories-downloadBtn {
	position: absolute;
	top: 0em;
	right: 0em;
	width: 7em;
	height: 7em;
	background-image: url('../../assets/images/icon-download.svg');
	background-size: 5em auto;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 2;
}

.Stories-header {
	font-size: 1.875em;
	text-align: left;
	color: white;
	h2 {
		font-size: 1.833em;
		font-family: Antropos;
		margin-top: 0;
	}
}

.Stories-text {
	margin-top: 1.25em;
	font-size: 2em;
	text-align: left;
	color: white;
}

.Stories-body {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: calc(100% - 25em);
	text-align: center;
}

.Stories-part {
	position: relative;
	vertical-align: top;
	height: 100%;
	display: inline-block;
	width: 33.1em;
	overflow: hidden;
	&::-webkit-scrollbar{display: none;}
	&:nth-child(2) {
		.Stories-partBody,
		.Stories-partHeader {
			background-color: darken(rgba($lemon, 0.75), 18%);
		}
	}
}

.Stories-partHeader {
	font-family: Antropos;
	width: 100%;
	background-color: rgba($lemon, 0.75);
	color: $green;
	text-transform: capitalize;
	font-size: 2.5em;
	line-height: 1.85;
	font-weight: bold;
	margin: 2px;
	@include box-shadow(0, 0.075em, 0.150em, 0, rgba(black, .5)); 
}

.Stories-partBody {
	height: calc(100% - 4.5em);
	width: 100%;
	overflow: auto;
	padding: 1em 2em 1em 2em;
	margin: 2px;
	background-color: rgba($lemon, 0.75);
	@include box-shadow(0, 0.1875em, 0.1875em, 0, rgba(black, .5)); 
	&::-webkit-scrollbar{display:none;}
}

.Stories-activity {
	position: relative;
	width: 100%;
	height: 10em;
	margin-top: 0.75em;
	background-image: url('../../assets/images/box-story.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: auto 100%;
}

.Stories-activityText {
	width: 100%;
	height: 100%;
	font-size: 1.5625em;
	font-weight: bold;
	text-align: left;
	padding: 2em 0  0 2em;
	overflow: hidden;
	@include no-select();
}