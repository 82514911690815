@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: $green;
	background-image: url('../../assets/images/loading.gif');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
}