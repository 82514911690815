@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChapterOverview {
	@include flex('center', 'center');
	position: relative;
	height: 100%;
	width: 100%;	
	background-image: url('../../assets/images/background-1.jpg');
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	overflow:hidden;
}

.ChapterOverview-menu {
	position: absolute;
	top: -3em;
	left: -1em;
	width: 25em;
	height: 25em;
	padding: 10em 0 0 2em;
	text-align: left;
	background-image: url('../../assets/images/background-menu.png');
	background-size: contain;
	background-position: left top;
	background-repeat: no-repeat;
	ul {
		list-style: none;
		padding-left: 1em;
	}		
	li {
		position: relative;
		font-size: 2em;
		line-height: 1.5;
		font-weight: bold;
		color: $green;
		padding-left: 1em;
		cursor: pointer;
		&:hover {@include scale(1.01);}	
		&:before {
			content: '';
			position: absolute;
			background-image: url('../../assets/images/icon-menu-bullet.svg');
			width: 1em;
			height: 1em;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			left: 0;
			top: 0.25em;
		}
	}
}
.ChapterOverview-logo {
	position: absolute;
	left: 3em;
	top: 4.5em;
	height: 4em;
	width: 18em;
	background-image: url('../../assets/images/logo-ask-og-liv.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: left center;
}

.ChapterOverview-downloadBtn {
	position: absolute;
	top: 0em;
	right: 0em;
	width: 7em;
	height: 7em;
	background-image: url('../../assets/images/icon-download.svg');
	background-size: 5em auto;
	background-repeat: no-repeat;
	background-position: center center;
	z-index: 2;
}

.ChapterOverview-chapters {
	width: 100%;
	margin-top: 10em;
	@include flex('center', 'center');
}

.ChapterOverview-chapter {
	text-align: center;
	margin: 2em 5.5em 0em 3.5em;
	width: 20em;
	height: 30em;
	background-image: url('../../assets/images/chapter-overview-1.svg');
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: top center;
	cursor: pointer;
	&:hover{@include scale(1.01);}
	&--2 {background-image: url('../../assets/images/chapter-overview-2.svg');}
	&--3 {background-image: url('../../assets/images/chapter-overview-3.svg');}
}

.ChapterOverview-badges {
	position: relative;
	width: 100%;
	height: 100%;
}
.ChapterOverview-aboutBtn {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 7em;
	background-image: url('../../assets/images/logo-blivnaturligvis-light.svg');
	background-repeat: no-repeat;
	background-size: 25em auto;
	background-position: center center;
}

.ChapterOverview-plant {
	position: absolute;
	background-size: contain;
	background-repeat: no-repeat;
	z-index: 1;

	&--bottomLeft {
		width: 12em;
		height: 15em;
		bottom: -4.5em;
		left: 4em;
		background-image: url('../../assets/images/plants/plant9.svg');
		transform: rotate(-8deg);
		animation: sway8 7.7s ease-in-out 0s infinite;
	}

	&--bottomRight {
		bottom: -3em;
		right: -8em;
		width: 18em;
		height: 15em;
		background-image: url('../../assets/images/plants/plant10.svg');
		transform: rotate(-8deg);
		animation: sway8 7.8s ease-in-out 0s infinite;
	}

	&--topRight {
		top: 5em;
		right: -2em;
		width: 18em;
		height: 15em;
		background-image: url('../../assets/images/plants/plant11.svg');
		animation: sway9 8.1s ease-in-out 0s infinite;
		-webkit-animation: sway9 8.1s ease-in-out 0s infinite;
	}

	&--topCenter{
		top: -5em;
		left: 35em;
		width: 18em;
		height: 15em;
		background-image: url('../../assets/images/plants/plant4.svg');
		animation: sway10 8s ease-in-out 0s infinite;
	}
}
