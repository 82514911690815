@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ChapterConfigure {
	position: relative;
	background-image: url('../../assets/images/background-forest2.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
	height: 100%;
	overflow: hidden;
	user-select: none;
}

.ChapterConfigure-header {
	height: 10em;
	color: white;
}

.ChapterConfigure-chapterMenu {
	position: relative;
	width: 20em;
	left: 15em;
	top: 1.5em;
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}

.ChapterConfigure-chapterItem {
	width: 6.5em;
	height: 6.5em;
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
	&:hover {@include scale(1.01);}
	&--1 {
		background-image: url('../../assets/images/chapter-nav-1.svg');
		&.ChapterConfigure-chapterItem--selected {background-image: url('../../assets/images/chapter-nav-1-selected.svg');}
	}
	&--2 {
		background-image: url('../../assets/images/chapter-nav-2.svg');
		&.ChapterConfigure-chapterItem--selected {background-image: url('../../assets/images/chapter-nav-2-selected.svg');}
	}
	&--3 {
		background-image: url('../../assets/images/chapter-nav-3.svg');
		&.ChapterConfigure-chapterItem--selected {background-image: url('../../assets/images/chapter-nav-3-selected.svg');}
	}
}

.ChapterConfigure-infoIcon {
	position: absolute;
	top: 0;
	right: 0;
	width: 8em;
	height: 8.5em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 4em auto;
	background-position: top 2.25em right 1.5em;
	&:hover {@include scale(1.01);}
}

.ChapterConfigure-materials {
	font-size: 2.4em;
	position: absolute;
	top: 0.7em;
	right: 5em;
	width: 11em;
	display: flex;
	justify-content: flex-start;
	flex-wrap: nowrap;
	flex-direction: row;
}
.ChapterConfigure-materialsText {
	margin-top: 0.6em;
	font-family: Antropos;
}
.ChapterConfigure-materialsIcon {
	width: 3em;
	height: 3em;
	background-image: url('../../assets/images/icon-pdf-white.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: left;
	&.animate {
		-webkit-animation: shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		animation: shake 0.8s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	}
}

.ChapterConfigure-textBody {
	text-align: center;
}

.ChapterConfigure-navigationButtons {
  position: relative;
   text-align: right;
	width: 30vw;
	display: inline-block;
	bottom: 5em;
	right: 5em;
}

.ChapterConfigure-body {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: calc(100% - 12em);
	text-align: center;
}

.ChapterConfigure-part {
	position: relative;
	display: inline-block;
	vertical-align: top;
	height: 100%;
	width: 33.1em;
	overflow: hidden;
	&::-webkit-scrollbar{display: none;}
	&:nth-child(2) {
		.ChapterConfigure-partBody,
		.ChapterConfigure-partHeader {
			background-color: darken(rgba($lemon, 0.75), 18%);
		}
	}
}

.ChapterConfigure-partHeader {
	width: 100%;
	font-family: Antropos;
	background-color: rgba($lemon, 0.75);
	color: $green;
	text-transform: capitalize;
	font-size: 2.5em;
	line-height: 1.85;
	font-weight: bold;
	margin: 2px;
	@include box-shadow(0, 0.075em, 0.150em, 0, rgba(black, .5)); 
}

.ChapterConfigure-partBody {
	height: calc(100% - 4.5em);
	width: 100%;
	overflow: auto;
	padding-top: 1em;
	margin: 2px;
	background-color: rgba($lemon, 0.75);
	@include box-shadow(0, 0.1875em, 0.1875em, 0, rgba(black, .5)); 
	&::-webkit-scrollbar{display:none;}
}

.ChapterConfigure-activity {
	position: relative;
	width: 100%;
	min-height: 15em;
	padding: 2em 4em;
	margin-top: 0.75em;
	background-image: url('../../assets/images/box.svg');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 90% auto;
	text-align: left;
	&--selected {background-image: url('../../assets/images/box-selected.svg');}
	&--story {
		&::after {
			content: '';
			position: absolute;
			bottom: 2.5em;
			right: 3.25em;
			width: 3.5em;
			height: 3em;
			background-image: url('../../assets/images/icon-book2.png');
			background-size: contain;
			background-position: bottom right;
			background-repeat: no-repeat;
		}
	}
}

.ChapterConfigure-activityTitle {
	width: 100%;
	font-weight: bold;
	font-size: 1.75em;
	margin-bottom: 0.5em;
	@include no-select();
}

.ChapterConfigure-activityDescription {
	font-size: 1.75em;
	overflow: hidden;
	@include no-select();
}

.ChapterConfigure-activityStatus {
	position: absolute;
	top: -0.4em;
	right: 1em;
	width: 4em;
	height: 4em;
	border-radius: 2em;
	border: 0.25em solid darken($beige, 8%);
	background-color: white;
	background-image: url('../../assets/images/icon-checkmark-grey.svg');
	background-size: auto 60%;
	background-position: center center;
	background-repeat: no-repeat;
	cursor: pointer;
	@include box-shadow(0.2em, 0.1em, 0.5em, 0, rgb(144, 146, 25));
	&--selected {
		background-image: url('../../assets/images/icon-checkmark.svg');
		border: 0.25em solid $green;
	}
	&:hover {
		@include box-shadow(0.25em, 0.15em, 0.5em, 0, rgb(144, 146, 25));	
		&:hover {@include scale(1.01);}
	}
}