@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Introduction {
	height: 100%;
	color: black;
	background-color: $beige;
	text-align: left;
	overflow: auto;
	@include no-select();
	&--popup {
		background-color: transparent;
		.Introduction-content {
			padding: 2em 4em;
		}
		.Introduction-text { 
			font-size: 2em;
			p, li {
				font-size: 1em;
			}
		}
	}
}

.Introduction-content {
	padding: 7.5em 12.75em 2em 12.75em;
}

.Introduction-text {
	font-size: 1.875em;
	p {
		font-size: 1.125em;
		line-height: 1.5em;
	}
	h2, h3{
		color: $green;
	}
	h2 {
		font-size: 1.833em;
		font-family: Antropos;
		margin-top: 0;
	}
	h3 {
		font-size: 1em; 
		font-weight: 700;
		margin-bottom: 0;
		margin-top: 2em;
	}
	ul {
		list-style: none;
		padding-left: 1em;
		margin-bottom: 0.5em;
	}
	li {
		font-size: 1.125em;
		line-height: 1.85em;
		padding-top: 0.5em;
		position: relative;
		&:before{
			content: '';
			position: absolute;
			background-image: url('../../assets/images/icon-menu-bullet.svg');
			width: 1.5em;
			height: 1.5em;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			left: -1.5em;
			top: 0.6em;
		}
	}
	.introParts {
		display: inline-block;
		width: 4.5em;
		height: 1.5em;
		background-image: url('../../assets/images/icon-chapter-1.svg'), url('../../assets/images/icon-chapter-2.svg'), url('../../assets/images/icon-chapter-3.svg');
		background-size: auto 100%, auto 100%, auto 100%;
		background-repeat: no-repeat, no-repeat, no-repeat;
		background-position: left 0 bottom, left 1.5em bottom, left 3em bottom;

	}
	.introCheck,
	.introMateriallistIcon,
	.introBadgeIcon {
		display: inline-block;
		width: 1em;
		height: 1em;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: contain;
	}
	.introCheck {background-image: url('../../assets/images/icon-checkmark.svg');}
	.introMateriallistIcon {
		background-image: url('../../assets/images/icon-pdf.svg');
	}
	.introBadgeIcon {
		background-image: url('../../assets/images/badges/cave-active.png');

	}
}