@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Button {
	display: inline-block;
	width: 6.25em;
	font-weight: bold;
	text-transform: uppercase;
	text-align: center;
	color: white;
	background-color: $green;
	padding: 0.25em 0;
	border: 0.0625em solid $grey-light;
	border-radius: 0.25em;
	text-decoration: none;
	outline: none;
	touch-action: manipulation;
	pointer-events: auto;	
	cursor: pointer;
	@include no-select();
	@include box-shadow(0, 0.1875em, 0.1875em, 0, rgba(black, .6)); 
	&:hover,
	&:focus,
	&:active {
		background-color: darken($green, 5%);
	}

	&--disabled {
		background: grey;
		cursor: not-allowed;
		&:hover,
		&:focus,
		&:active {
			background: grey;
		}
	}

	&--loading {
		background-image: url('../../assets/images/icon-loading.svg');
		background-size: 1.75em auto;
		background-position: right 0.2em center;
		background-repeat: no-repeat;
		&:hover,
		&:focus,
		&:active {
			background-image: url('../../assets/images/icon-loading.svg');
			background-size: 1.75em auto;
			background-position: right 0.2em center;
			background-repeat: no-repeat;
		}
	}

	&--reset {
		position: absolute;
		top: 1em;
		left: 1em;
	}

	&--cookie {
		font-size: 2.2em;
		line-height: 2.6;
		font-weight: 700;
		width: 7.4em;
		text-transform: none;
		border-radius: 0.667em;
		border: 0.1em solid white;
		background-color: $green-light;
		padding: 0;
		@include box-shadow(0, 0, 0, 0, rgba(black, 0)); 
		&:hover,
		&:focus,
		&:active {
			background-color: darken($green-light, 5%);
		}
	}

	&--printActivity,
	&--printPlan,
	&--printStory,
	&--printStories {
		display: inline-block;
		width: 16em;
		text-transform: none;
		color: black;
		background-color: $yellow;
		background-image: none;
		border: 0.0125em solid white;
		margin: auto;
		border-radius: 0.4em;
		padding: 0.75em 0;
		@include box-shadow(0, 0.10em, 0.30em, 0, rgba(black, .25)); 
		&:hover,
		&:focus,
		&:active {
			@include scale(1.01);
			background-color: darken($yellow, 5%);
			
		}
	}
	&--printPlan,
	&--printStories {
		background-color: $green-light;
		&:hover,
		&:focus,
		&:active {
			background-color: darken($green-light, 5%);
		}
	}
	&--sendEmail {
		border-radius: 0.4em;
		padding: 0.35em 0;
		color: white;
		background-color: $orange;
		background-image: none;
		@include box-shadow(0, 0.10em, 0.3em, 0, rgba(black, .25)); 
		&:hover,
		&:focus,
		&:active {
			
			background-color: darken($orange, 5%);
		}
	}
}

.Button--printActivity.Button--loading,
.Button--printPlan.Button--loading,
.Button--printStory.Button--loading,
.Button--printStories.Button--loading {
	background-image: url('../../assets/images/icon-loading2.svg');
}

.Button--sendEmail.Button--loading {
	background-image: url('../../assets/images/icon-loading.svg');
}