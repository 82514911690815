@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ActivityPopup {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 3;
	background-color: rgba($green, .5);
	user-select: none;
	&--show {@include flex('center','center');}
}

.ActivityPopup-body {
	position: relative;
	width: 80vw;
	padding: 2.25em;
	border-radius: 0.625em;
	background-image: url('../../assets/images/background-popup.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
}

.ActivityPopup-closeBtn {
	position: absolute;
	right: 3.5em;
	top: 2.5em;
	width: 3.0em;
	height: 3.0em;
	background-image: url('../../assets/images/icon-close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
	&:hover {@include scale(1.01);}
}

.ActivityPopup-title {
	font-size: 3.5em;
	font-family: Antropos;
	font-weight: bold;
	color: $green;
	text-align: center;
	margin-bottom: 0;
	margin-top: 0.75em;
}

.ActivityPopup-activity {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	height: 35em;
	overflow: auto;
	user-select: none;
	&::-webkit-scrollbar{display: none;}
}

.ActivityPopup-tracks,
.ActivityPopup-book {
	position: absolute;
	top: 0em;
	left: calc(50% - 5em);
	width: 10em;
	height: 10em;
	z-index: 0;
	@include opacity(0.5);
	background-image: url('../../assets/images/background-tracks.svg');
	background-repeat: no-repeat;
	background-size: contain;
}
.ActivityPopup-book {
	left: calc(50% - 7em);
	width: 14em;
	height: 14em;
	background-image: url('../../assets/images/background-book.svg');
}

.ActivityPopup-text {
	position: relative;
	height: 100%;
	font-size: 2em;
	text-align: center;
	padding: 3em 1em 1em 1em;
	user-select: none;
	h2 {
		display: inline-block;
		margin: 0;
		margin-bottom: 0.5em;
		font-weight: 600;
	}
	blockquote {
		display:none;
	}
	h3 {
		font-size: 1em;
		font-weight: bold;
		margin-bottom: 0em;
	}
	h4 {
		font-size: 1em;
		font-weight: bold;
		margin-bottom: 0em;
	}
	p {
		font-size: 0.8em;
		margin-top: 0em;
	}
	li {
		font-size: 0.8em;
	}
	ul {
		margin-bottom: 0.5em;
	}
}

.ActivityPopup-textTitle {
	font-weight: bold;
	text-align: center;
	padding-bottom: 0;
}

.ActivityPopup-openActivity,
.ActivityPopup-readStory {
	position: absolute;
	left: 5em;
	bottom: 0em;
	width: 9em;
	font-size: 2em;
	margin: 0 auto;
	padding: 0.2em 0.0em 0.2em 0.0em;
	color: white;
	background-color: $green-light;
	text-align: center;
	font-weight: bold;
	border-radius: 0.5em;
	cursor: pointer;
	&:hover{@include scale(1.01);}
}

.ActivityPopup-story {
	position: relative;
	display: inline-block;
	vertical-align: top;
	width: 50%;
	height: 35em;
	border-left: 0.15em solid #F5E5C7;	
	overflow: scroll;
	&::-webkit-scrollbar {display: none;}
}

.ActivityPopup-toggleActivity {
	font-size: 2em;
	margin-top: 2em;
	position: relative;
	width: 100%;
	text-align: center;
}
.ActivityPopup-label {
	display: inline-block;
	vertical-align: middle;
	color: $green;
	font-weight: 700;
}
.ActivityPopup-checkBox {
	display: inline-block;
	vertical-align: middle;
	margin-left: 0.5em;
	width: 2em;
	height: 2em;
	padding: 0.2em;
	border: 0.15em solid $green;
	border-radius: 2em;
	background-color: white;
	&--selected {
		background-image: url('../../assets/images/icon-checkmark.svg');
		background-size: auto 70%;
		background-position: center center;
		background-repeat: no-repeat;
	}
}



.ActivityPopup-activity.ActivityPopup-activity--fullWidth {
	width: 100%;
	.ActivityPopup-title {
		margin-bottom: 0.75em;
	}
	.ActivityPopup-tracks {
		left: calc(50% - 6em);
		width: 12em;
		height: 12em;
	}
	.ActivityPopup-text {
		margin: auto;
		padding: 2em 6em;
	}
	.ActivityPopup-openActivity {
		left: calc(50% - 4.5em);
	}
}