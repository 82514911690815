@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Audio {
	width: 35em;
	.rhap_container {
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		display: block;
		background-color: transparent;
		box-shadow: none;
		padding: 1em 1.125em;
		&:focus {outline: none;}

		.rhap_controls-section {
			width: 7.5em;
			display: inline-block;
			vertical-align: top;
			.rhap_additional-controls, 
			.rhap_volume-controls {
				display: none;
			}
			.rhap_main-controls {
				.rhap_play-pause-button {
					font-size: 4.375em;
					width: 1em;
					height: 1em;
				}
				.rhap_main-controls-button {
					color: $orange-light;
				}
			}
		}

		.rhap_progress-section {
			display: block;
			width: 24.5em;
			padding-top: 1em;
			.rhap_progress-container {
				height: 2em;
				margin: 0;
				&:focus {outline: none;}
				.rhap_progress-bar {
					height: 0.25em;
					border-radius: 1em;
				}
				.rhap_progress-bar-show-download {
					background-color: white;
					border-radius: 1em;
				}
				.rhap_progress-filled {
					background-color: $orange-light;
					border-radius: 1em;
				}
				.rhap_download-progress {
					background-color: rgba(white, 0.5);
					border-radius: 1em;
				}
				.rhap_progress-indicator {
					width: 2.25em;
					height: 2.25em;
					margin-left: -1.125em;
					top: -0.9em;
					background-color: transparent;
					box-shadow: none;
					background-image: url('../../assets/images/icon-circle.png');
					background-size: contain;
					background-repeat: no-repeat;
					background-position: center center;

				}
			}
			.rhap_current-time, 
			.rhap_current-left-time,
			.rhap_total-time {
				font-size: 1em;
				display: inline-block;
				vertical-align: top;
				width: 50%;
				color: #fff;
				text-align: left;
			}
			.rhap_current-left-time,
			.rhap_total-time {
				text-align: right;
			}
		}
	}
}

.Audio.Audio--text {
	width: 32.5em;
	height: 6.625em;
	background-image: url('../../assets/images/background-audio.svg');
	background-size: 35.25em auto;
	background-position: bottom left;
	background-repeat: no-repeat;
}

.Audio.Audio--animation {
	display: inline-block;
	width: 60em;
	height: 8.75em;
	.rhap_container {
		padding: 0 1.125em;
		width: 60em;
		min-width: 60em;
		max-width: 60em;
		.rhap_controls-section {
			width: 12.5em;
			.rhap_main-controls {
				.rhap_play-pause-button {
					font-size: 8.75em;
				}
			}
		}

		.rhap_progress-section {
			display: block;
			width: 47.5em;
			padding-top: 2.5em;
			.rhap_progress-container {
				height: 4em;
				.rhap_progress-bar {
					height: 0.5em;
				}
				.rhap_progress-indicator {
					width: 4.5em;
					height: 4.5em;
					margin-left: -2.25em;
					top: -1.7em;
				}
			}
			.rhap_current-time, 
			.rhap_current-left-time,
			.rhap_total-time {
				font-size: 2em;
				// color: #323232;
				color: white;
			}
		}
	}
}