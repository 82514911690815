@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.CookieConsent {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 14em;
	background: rgba($green, 0.8);
	color: white;
	@include opacity(0);
	padding-right: 28em;
	padding: 0 28em 0 5.75em;
	-webkit-animation: fadeInCookie 1s ease-in-out 1s 1 forwards;
	animation: fadeInCookie 1s ease-in-out 1s 1 forwards;
	z-index: 2;
}

.CookieConsent-text {
	width: 100%;
	height: 100%;
	@include flex('flex-start', 'center');
	font-size: 2.2em;
	line-height: 1.75;
	text-align: left;
}

.CookieConsent-button {
	position: absolute;
	top: 0;
	right: 0;
	width: 28em;
	height: 100%;
	@include flex('center', 'center');
}