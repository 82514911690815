@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.MusicAndSpirits {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/music-and-spirits/background.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.MusicAndSpirits-branch,
.MusicAndSpirits-characters,
.MusicAndSpirits-shamanEyes,
.MusicAndSpirits-askEyes,
.MusicAndSpirits-livEye,
.MusicAndSpirits-fire,
.MusicAndSpirits-firestone,
.MusicAndSpirits-smoke {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}


.MusicAndSpirits-branch {
	background-image: url('../../../assets/images/animations/music-and-spirits/branch.png');
	@include translateAndRotate(0, 0, 0, 2deg);
	-webkit-animation: branch 3.5s ease-in-out forwards infinite;
	animation: branch 3.5s ease-in-out forwards infinite;
}


.MusicAndSpirits-characters {
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/music-and-spirits/characters.png');
	&--dark {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/music-and-spirits/characters-dark.png');
		-webkit-animation: character-glow-dark 10s ease-in-out 0s infinite;
		animation: character-glow-dark 10s ease-in-out 0s infinite;
	}
	&--bright {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/music-and-spirits/characters-bright.png');
		-webkit-animation: character-glow-bright 10s ease-in-out 0s infinite;
		animation: character-glow-bright 10s ease-in-out 0s infinite;
	}
}

.MusicAndSpirits-shamanEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/music-and-spirits/shaman-eyes.png');
	-webkit-animation: eye-blink 12s linear 4s infinite;
	animation: eye-blink 12s linear 4s infinite;
}
.MusicAndSpirits-askEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/music-and-spirits/ask-eyes.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}
.MusicAndSpirits-livEye {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/music-and-spirits/liv-eye.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}

.MusicAndSpirits-smoke {
	@include translate3d(0, 0, 0);
	&--1 {
		@include opacity(1);
		background-image: url('../../../assets/images/animations/music-and-spirits/smoke-blur-1.png');	
		-webkit-animation: smoke-1 10s ease-in-out 0s infinite;
		animation: smoke-1 10s ease-in-out 0s infinite;
	}
	&--2 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/music-and-spirits/smoke-blur-2.png');	
		-webkit-animation: smoke-2 10s ease-in-out 0s infinite;
		animation: smoke-2 10s ease-in-out 0s infinite;
	}
	&--3 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/music-and-spirits/smoke-blur-3.png');	
		-webkit-animation: smoke-3 10s ease-in-out 0s infinite;
		animation: smoke-3 10s ease-in-out 0s infinite;
	}
}

.MusicAndSpirits-fire {
	background-image: url('../../../assets/images/animations/music-and-spirits/fire.png');
}
.MusicAndSpirits-firestone {
	background-image: url('../../../assets/images/animations/music-and-spirits/firestone.png');
}

.MusicAndSpirits-fireeffects {
	display: block;
	position: absolute;
	width: 10em;
	height: 40em;
	margin-left: -5em;
	margin-top: -37.5em;
}
.MusicAndSpirits-glow {
	position: absolute;
	&--1 {
		bottom: 0em;
		left: calc(50% - 3.25em);
		width: 6.5em;
		height: 8em;
		border-radius: 5.5em;
		background-color: #f8fa92;
		@include opacity(1);
		@include blur(2.5em);
		@include translate3d(0, 0, 0);
		-webkit-animation: glow 10s ease-in-out 0s infinite;
		animation: glow 10s ease-in-out 0s infinite;
	}
	&--2 {
		bottom: 0em;
		left: calc(50% - 3em);
		width: 5em;
		height: 5em;
		border-radius: 4.5em;
		background-color: #d35400;
		@include opacity(0.5);
		@include box-shadow(0, 0, 5em, 0, #e69d6c);
		@include blur(1em);
		@include translate3d(0, 0, 0);
		animation: glow 10s ease-in-out 0s infinite;
		-webkit-animation: glow 10s ease-in-out 0s infinite;		
	}
}
.MusicAndSpirits-spark {
	position: absolute;
	bottom: 3em;
	left: calc(50% - 2em);
	width: 2em;
	height: 2em;
	@include opacity(0);
	@include translate3d(0, 0, 0);
	&--1 {
		-webkit-animation: spark1b 3s linear 2s infinite;
		animation: spark1b 3s linear 2s infinite;
	}
	&--2 {
		-webkit-animation: spark2b 4s linear 1.5s infinite;
		animation: spark2b 4s linear 1.5s infinite;
		left: calc(50% - 4em);
	}
	&--3 {
		-webkit-animation: spark3b 6s linear 3s infinite;
		animation: spark3b 6s linear 3s infinite;
		left: calc(50% - 3em);
	}
	&--4 {
		-webkit-animation: spark4b 5s linear 2s infinite;
		animation: spark4b 5s linear 2s infinite;
		left: 50%;
	}
	&--5 {
		-webkit-animation: spark5b 7s linear 1s infinite;
		animation: spark5b 7s linear 1s infinite;
		left: calc(50% - 2em);
	}
	&:after {
		content: '';
		position: absolute;
		top: 2em;
		left: 2em;
		width: 1px;
		height: 1px;
		border-radius: 3px;
		background-color: #f1d166;
		border: 2px solid #f1e3a0;
		@include box-shadow(0, 0, 10px, 0, #f3d032);
		@include blur(1px);
	}
}

@-webkit-keyframes branch {
	0% {@include rotate(0deg);}
	50% {@include rotate(-5deg);}
	100% {@include rotate(0deg);}
}
@keyframes branch {
	0% {@include rotate(0deg);}
	50% {@include rotate(-5deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes spark1b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -24em, 0, 60deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -32em, 0, 75deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -40em, 0, 90deg);}
}
@keyframes spark1b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -24em, 0, 60deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -32em, 0, 75deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -40em, 0, 90deg);}
}

@-webkit-keyframes spark2b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -24em, 0, 30deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -32em, 0, 15deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -40em, 0, 0deg);}
}
@keyframes spark2b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -24em, 0, 30deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -32em, 0, 15deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -40em, 0, 0deg);}
}

@-webkit-keyframes spark3b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 0deg)}
  10% {@include opacity(1); @include translateAndRotate(0.5em, 0, 0, 45deg);}
	15% {@include opacity(1); @include translateAndRotate(1em, -24em, 0, 35deg);}
	20% {@include opacity(0.8); @include translateAndRotate(1.5em, -32em, 0, 25deg);}
	25% {@include opacity(0); @include translateAndRotate(2em, -40em, 0, 15deg);}
	100% {@include opacity(0); @include translateAndRotate(2em, -40em, 0, 0deg);}
}
@keyframes spark3b {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 0deg)}
  10% {@include opacity(1); @include translateAndRotate(0.5em, 0, 0, 45deg);}
	15% {@include opacity(1); @include translateAndRotate(1em, -24em, 0, 35deg);}
	20% {@include opacity(0.8); @include translateAndRotate(1.5em, -32em, 0, 25deg);}
	25% {@include opacity(0); @include translateAndRotate(2em, -40em, 0, 15deg);}
	100% {@include opacity(0); @include translateAndRotate(2em, -40em, 0, 0deg);}
}

@-webkit-keyframes spark4b {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
	40% {@include opacity(1); @include translateAndRotate(1em, -16em, 0, 45deg);}
	80% {@include opacity(0.8); @include translateAndRotate(2em, -32em, 0, -45deg);}
	100% {@include opacity(0); @include translateAndRotate(2.5em, -40em, 0, -10deg);}
}
@keyframes spark4b {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
	40% {@include opacity(1); @include translateAndRotate(1em, -16em, 0, 45deg);}
	80% {@include opacity(0.8); @include translateAndRotate(2em, -32em, 0, -45deg);}
	100% {@include opacity(0); @include translateAndRotate(2.5em, -40em, 0, -10deg);}
}

@-webkit-keyframes spark5b {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
	40% {@include opacity(1); @include translateAndRotate(-1em, -16em, 0, 90deg);}
	80% {@include opacity(0.8); @include translateAndRotate(-2em, -32em, 0, 110deg);}
	100% {@include opacity(0); @include translateAndRotate(-2.5em, -40em, 0, 130deg);}
}
@keyframes spark5b {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
	40% {@include opacity(1); @include translateAndRotate(-1em, -16em, 0, 90deg);}
	80% {@include opacity(0.8); @include translateAndRotate(-2em, -32em, 0, 110deg);}
	100% {@include opacity(0); @include translateAndRotate(-2.5em, -40em, 0, 130deg);}
}

@-webkit-keyframes smoke-1 {
	0% {@include opacity(0.5);}
	35% {@include opacity(0);}
	60% {@include opacity(0.25);}
	80% {@include opacity(0);}
	100% {@include opacity(0.5);}
}
@keyframes smoke-1 {
	0% {@include opacity(0.5);}
	35% {@include opacity(0);}
	60% {@include opacity(0.25);}
	80% {@include opacity(0);}
	100% {@include opacity(0.5);}
}

@-webkit-keyframes smoke-2 {
	0% {@include opacity(0.25);}
	30% {@include opacity(0.5);}
	40% {@include opacity(0);}
	55% {@include opacity(0.5);}
	100% {@include opacity(0.25);}
}
@keyframes smoke-2 {
	0% {@include opacity(0.25);}
	30% {@include opacity(0.5);}
	40% {@include opacity(0);}
	55% {@include opacity(0.5);}
	100% {@include opacity(0.25);}
}

@-webkit-keyframes smoke-3 {
	0% {@include opacity(0);}
	40% {@include opacity(0.5);}
	60% {@include opacity(0);}
	80% {@include opacity(0.5);}
	100% {@include opacity(0);}
}
@keyframes smoke-3 {
	0% {@include opacity(0);}
	40% {@include opacity(0.5);}
	60% {@include opacity(0);}
	80% {@include opacity(0.5);}
	100% {@include opacity(0);}
}