@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.BuildHuts {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/build-huts/background.jpg');
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
}

.BuildHuts-hare,
.BuildHuts-hare-ear, 
.BuildHuts-bush, 
.BuildHuts-bird, 
.BuildHuts-askHand-1,
.BuildHuts-askHand-2,
.BuildHuts-askHand-3,
.BuildHuts-askEye, 
.BuildHuts-livEye {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
}

.BuildHuts-hare {
	background-image: url('../../../assets/images/animations/build-huts/hare-body.png');
	-webkit-animation: hare-position 10s linear 0s infinite;
	animation: hare-position 10s linear 0s infinite;
}
.BuildHuts-hare-ear {
	position: relative;
	background-image: url('../../../assets/images/animations/build-huts/hare-ear.png');
	-webkit-animation: hare-ear 10s linear 0s infinite;
	animation: hare-ear 10s linear 0s infinite;
}

.BuildHuts-bush {
	background-image: url('../../../assets/images/animations/build-huts/bush.png');
}

.BuildHuts-bird {
	background-image: url('../../../assets/images/animations/build-huts/bird.png');
	-webkit-animation: bird1 18s linear 5s infinite;
	animation: bird1 18s linear 5s infinite;
}

.BuildHuts-askHand-1 {
	@include opacity(1);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/build-huts/ask-hand-1.png');
	-webkit-animation: askHand-1 50s linear 2s infinite;
	animation: askHand-1 60s linear 7s infinite;
}
.BuildHuts-askHand-2 {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/build-huts/ask-hand-2.png');
	-webkit-animation: askHand-2 50s linear 2s infinite;
	animation: askHand-2 60s linear 7s infinite;
}
.BuildHuts-askHand-3 {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/build-huts/ask-hand-3.png');
	-webkit-animation: askHand-3 50s linear 2s infinite;
	animation: askHand-3 60s linear 7s infinite;
	
}

.BuildHuts-askEye {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/build-huts/ask-eye.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}
.BuildHuts-livEye {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/build-huts/liv-eye.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}

.BuildHuts-sunbeam {
	position: absolute;
	left: 0;
	top: -2em;
	width: 100%;
	height: 100%;
	background-position: top right;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: url('../../../assets/images/animations/shared/sunbeams1-blur5.png');
	@include origin(top, center);
	@include translate3d(0, 0, 0,);
	&--1 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur5.png');}
	&--2 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur3.png');}
	&--3 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur1.png');}
	&--4 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur2.png');}
	&--5 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur1.png');}
	&--6 {background-image: url('../../../assets/images/animations/shared/sunbeams1-blur4.png');}

	&--1 {
		@include opacity(1);
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		left: 1em;
	}
	&--2 {
		@include opacity(0);
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 5s infinite;
		left: -3em;
	}
	&--3 {
		@include opacity(0);
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		left: -5em;
	}
	&--4 {
		@include opacity(1);
		left: 3em;
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
	}
	&--5 {
		@include opacity(0);
		left: -2em;
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 5s infinite;

	}
	&--6 {
		@include opacity(0);
		left: 0em;
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
	}
}

/* Hare */
@-webkit-keyframes hare-position {
	0% {@include translate3d(0, 2%, 0);}
	25% {@include translate3d(0, 2%, 0);}
	26% {@include translate3d(0, 0, 0);}
	55% {@include translate3d(0, 0, 0);}
	56% {@include translate3d(0, 2%, 0);}
	100% {@include translate3d(0, 2%, 0);}
}
@keyframes hare-position {
	0% {@include translate3d(0, 2%, 0);}
	25% {@include translate3d(0, 2%, 0);}
	26% {@include translate3d(0, 0, 0);}
	55% {@include translate3d(0, 0, 0);}
	56% {@include translate3d(0, 2%, 0);}
	100% {@include translate3d(0, 2%, 0);}
}
@-webkit-keyframes hare-ear {
	0% {@include rotate(0deg);}
	34.999% {@include rotate(0deg);}
	35% {@include rotate(10deg);}
	36% {@include rotate(-5deg);}
	37% {@include rotate(0deg);}
	43.999% {@include rotate(0deg);}
	44% {@include rotate(10deg);}
	45% {@include rotate(-5deg);}
	46% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes hare-ear {
	0% {@include rotate(0deg);}
	34.999% {@include rotate(0deg);}
	35% {@include rotate(10deg);}
	36% {@include rotate(-5deg);}
	37% {@include rotate(0deg);}
	43.999% {@include rotate(0deg);}
	44% {@include rotate(10deg);}
	45% {@include rotate(-5deg);}
	46% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

/* Ask's hand */
@-webkit-keyframes askHand-1 {
	0% {@include opacity(1);} 
	0.1999% {@include opacity(1);}
	0.2% {@include opacity(0);}
	0.9999% {@include opacity(0);}
	1% {@include opacity(1);}
	8.1999% {@include opacity(1);}
	8.2% {@include opacity(0);}
	8.999% {@include opacity(0);}
	9% {@include opacity(1);}
	22.1999% {@include opacity(1);}
	22.2% {@include opacity(0);}
	22.999% {@include opacity(0);}
	23% {@include opacity(1);}
	26.1999% {@include opacity(1);}
	26.2% {@include opacity(0);}
	27.1999% {@include opacity(0);}
	27.2% {@include opacity(1);}
	34.1999% {@include opacity(1);}
	34.2% {@include opacity(0);}
	34.999% {@include opacity(0);}
	35% {@include opacity(1);}
	44.1999% {@include opacity(1);}
	44.2% {@include opacity(0);}
	44.999% {@include opacity(0);}
	45% {@include opacity(1);}
	54.1999% {@include opacity(1);}
	54.2% {@include opacity(0);}
	54.999% {@include opacity(0);}
	55% {@include opacity(1);}
	68.1999% {@include opacity(1);}
	68.2% {@include opacity(0);}
	68.999% {@include opacity(0);}
	69% {@include opacity(1);}
	72.1999% {@include opacity(1);}
	72.2% {@include opacity(0);}
	73.299% {@include opacity(0);}
	73.3% {@include opacity(1);}
	79.999% {@include opacity(1);}
	80% {@include opacity(0);}
	80.999% {@include opacity(0);}
	81% {@include opacity(1);}
	100% {@include opacity(1);}
}
@keyframes askHand-1 {
	0% {@include opacity(1);} 
	0.1999% {@include opacity(1);}
	0.2% {@include opacity(0);}
	0.9999% {@include opacity(0);}
	1% {@include opacity(1);}
	8.1999% {@include opacity(1);}
	8.2% {@include opacity(0);}
	8.999% {@include opacity(0);}
	9% {@include opacity(1);}
	22.1999% {@include opacity(1);}
	22.2% {@include opacity(0);}
	22.999% {@include opacity(0);}
	23% {@include opacity(1);}
	26.1999% {@include opacity(1);}
	26.2% {@include opacity(0);}
	27.1999% {@include opacity(0);}
	27.2% {@include opacity(1);}
	34.1999% {@include opacity(1);}
	34.2% {@include opacity(0);}
	34.999% {@include opacity(0);}
	35% {@include opacity(1);}
	44.1999% {@include opacity(1);}
	44.2% {@include opacity(0);}
	44.999% {@include opacity(0);}
	45% {@include opacity(1);}
	54.1999% {@include opacity(1);}
	54.2% {@include opacity(0);}
	54.999% {@include opacity(0);}
	55% {@include opacity(1);}
	68.1999% {@include opacity(1);}
	68.2% {@include opacity(0);}
	68.999% {@include opacity(0);}
	69% {@include opacity(1);}
	72.1999% {@include opacity(1);}
	72.2% {@include opacity(0);}
	73.299% {@include opacity(0);}
	73.3% {@include opacity(1);}
	79.999% {@include opacity(1);}
	80% {@include opacity(0);}
	80.999% {@include opacity(0);}
	81% {@include opacity(1);}
	100% {@include opacity(1);}
}

@-webkit-keyframes askHand-2 {
	0% {@include opacity(0);}
	0.1999% {@include opacity(0);}
	0.2% {@include opacity(1);}
	0.3999% {@include opacity(1);}
	0.4% {@include opacity(0);}
	0.5999% {@include opacity(0);}
	0.6% {@include opacity(1);}
	0.999% {@include opacity(1);}
	1% {@include opacity(0);}
	8.1999% {@include opacity(0);}
	8.2% {@include opacity(1);}
	8.4999% {@include opacity(1);}
	8.5% {@include opacity(0);}
	8.5999% {@include opacity(0);}
	8.6% {@include opacity(1);}
	8.999% {@include opacity(1);}
	9% {@include opacity(0);}
	22.1999% {@include opacity(0);}
	22.2% {@include opacity(1);}
	22.399% {@include opacity(1);}
	22.4% {@include opacity(0);}
	22.5999% {@include opacity(0);}
	22.6% {@include opacity(1);}
	22.999% {@include opacity(1);}
	23% {@include opacity(0);}
	26.1999% {@include opacity(0);}
	26.2% {@include opacity(1);}
	26.3999% {@include opacity(1);}
	26.4% {@include opacity(0);}
	26.5999% {@include opacity(0);}
	26.6% {@include opacity(1);}
	26.7999% {@include opacity(1);}
	26.8% {@include opacity(0);}
	26.999% {@include opacity(0);}
	27% {@include opacity(1);}
	27.1999% {@include opacity(1);}
	27.2% {@include opacity(0);}
	34.1999% {@include opacity(0);}
	34.2% {@include opacity(1);}
	34.3999% {@include opacity(1);}
	34.4% {@include opacity(0);}
	34.5999% {@include opacity(0);}
	34.6% {@include opacity(1);}
	34.999% {@include opacity(1);}
	35% {@include opacity(0);}
	44.1119% {@include opacity(0);}
	44.2% {@include opacity(1);}
	44.3999% {@include opacity(1);}
	44.4% {@include opacity(0);}
	44.5999% {@include opacity(0);}
	44.6% {@include opacity(1);}
	44.999% {@include opacity(1);}
	45% {@include opacity(0);}
	54.1999% {@include opacity(0);}
	54.2% {@include opacity(1);}
	54.3999% {@include opacity(1);}
	54.4% {@include opacity(0);}
	54.5999% {@include opacity(0);}
	54.6% {@include opacity(1);}
	54.999% {@include opacity(1);}
	55% {@include opacity(0);}
	68.1999% {@include opacity(0);}
	68.2% {@include opacity(1);}
	68.3999% {@include opacity(1);}
	68.4% {@include opacity(0);}
	68.5999% {@include opacity(0);}
	68.6% {@include opacity(1);}
	68.999% {@include opacity(1);}
	69% {@include opacity(0);}
	72.1999% {@include opacity(0);}
	72.2% {@include opacity(1);}
	72.3999% {@include opacity(1);}
	72.4% {@include opacity(0);}
	72.5999% {@include opacity(0);}
	72.6% {@include opacity(1);}
	72.7999% {@include opacity(1);}
	72.8% {@include opacity(0);}
	72.999% {@include opacity(0);}
	73% {@include opacity(1);}
	73.2999% {@include opacity(1);}
	73.3% {@include opacity(0);}
	80.1999% {@include opacity(0);}
	80.2% {@include opacity(1);}
	80.399% {@include opacity(1);}
	80.4% {@include opacity(0);}
	80.5999% {@include opacity(0);}
	80.6% {@include opacity(1);}
	80.999% {@include opacity(1);}
	81% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes askHand-2 {
	0% {@include opacity(0);}
	0.1999% {@include opacity(0);}
	0.2% {@include opacity(1);}
	0.3999% {@include opacity(1);}
	0.4% {@include opacity(0);}
	0.5999% {@include opacity(0);}
	0.6% {@include opacity(1);}
	0.999% {@include opacity(1);}
	1% {@include opacity(0);}
	8.1999% {@include opacity(0);}
	8.2% {@include opacity(1);}
	8.4999% {@include opacity(1);}
	8.5% {@include opacity(0);}
	8.5999% {@include opacity(0);}
	8.6% {@include opacity(1);}
	8.999% {@include opacity(1);}
	9% {@include opacity(0);}
	22.1999% {@include opacity(0);}
	22.2% {@include opacity(1);}
	22.399% {@include opacity(1);}
	22.4% {@include opacity(0);}
	22.5999% {@include opacity(0);}
	22.6% {@include opacity(1);}
	22.999% {@include opacity(1);}
	23% {@include opacity(0);}
	26.1999% {@include opacity(0);}
	26.2% {@include opacity(1);}
	26.3999% {@include opacity(1);}
	26.4% {@include opacity(0);}
	26.5999% {@include opacity(0);}
	26.6% {@include opacity(1);}
	26.7999% {@include opacity(1);}
	26.8% {@include opacity(0);}
	26.999% {@include opacity(0);}
	27% {@include opacity(1);}
	27.1999% {@include opacity(1);}
	27.2% {@include opacity(0);}
	34.1999% {@include opacity(0);}
	34.2% {@include opacity(1);}
	34.3999% {@include opacity(1);}
	34.4% {@include opacity(0);}
	34.5999% {@include opacity(0);}
	34.6% {@include opacity(1);}
	34.999% {@include opacity(1);}
	35% {@include opacity(0);}
	44.1119% {@include opacity(0);}
	44.2% {@include opacity(1);}
	44.3999% {@include opacity(1);}
	44.4% {@include opacity(0);}
	44.5999% {@include opacity(0);}
	44.6% {@include opacity(1);}
	44.999% {@include opacity(1);}
	45% {@include opacity(0);}
	54.1999% {@include opacity(0);}
	54.2% {@include opacity(1);}
	54.3999% {@include opacity(1);}
	54.4% {@include opacity(0);}
	54.5999% {@include opacity(0);}
	54.6% {@include opacity(1);}
	54.999% {@include opacity(1);}
	55% {@include opacity(0);}
	68.1999% {@include opacity(0);}
	68.2% {@include opacity(1);}
	68.3999% {@include opacity(1);}
	68.4% {@include opacity(0);}
	68.5999% {@include opacity(0);}
	68.6% {@include opacity(1);}
	68.999% {@include opacity(1);}
	69% {@include opacity(0);}
	72.1999% {@include opacity(0);}
	72.2% {@include opacity(1);}
	72.3999% {@include opacity(1);}
	72.4% {@include opacity(0);}
	72.5999% {@include opacity(0);}
	72.6% {@include opacity(1);}
	72.7999% {@include opacity(1);}
	72.8% {@include opacity(0);}
	72.999% {@include opacity(0);}
	73% {@include opacity(1);}
	73.2999% {@include opacity(1);}
	73.3% {@include opacity(0);}
	80.1999% {@include opacity(0);}
	80.2% {@include opacity(1);}
	80.399% {@include opacity(1);}
	80.4% {@include opacity(0);}
	80.5999% {@include opacity(0);}
	80.6% {@include opacity(1);}
	80.999% {@include opacity(1);}
	81% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes askHand-3 {
	0% {@include opacity(0);}
	0.3999% {@include opacity(0);}
	0.4% {@include opacity(1);}
	0.5999% {@include opacity(1);}
	0.6% {@include opacity(0);}
	8.4999% {@include opacity(0);}
	8.5% {@include opacity(1);}
	8.5999% {@include opacity(1);}
	8.6% {@include opacity(0);}
	22.3999% {@include opacity(0);}
	22.4% {@include opacity(1);}
	22.5999% {@include opacity(1);}
	22.6% {@include opacity(0);}
	26.3999% {@include opacity(0);}
	26.4% {@include opacity(1);}
	26.5999% {@include opacity(1);}
	26.6% {@include opacity(0);}
	26.7999% {@include opacity(0);}
	26.8% {@include opacity(1);}
	26.999% {@include opacity(1);}
	27% {@include opacity(0);}
	34.3999% {@include opacity(0);}
	34.4% {@include opacity(1);}
	34.5999% {@include opacity(1);}
	34.6% {@include opacity(0);}
	44.3999% {@include opacity(0);}
	44.4% {@include opacity(1);}
	44.5999% {@include opacity(1);}
	44.6% {@include opacity(0);}
	54.3999% {@include opacity(0);}
	54.4% {@include opacity(1);}
	54.5999% {@include opacity(1);}
	54.6% {@include opacity(0);}
	68.3999% {@include opacity(0);}
	68.4% {@include opacity(1);}	
	68.5999% {@include opacity(1);}
	68.6% {@include opacity(0);}
	72.3999% {@include opacity(0);}
	72.4% {@include opacity(1);}
	72.5999% {@include opacity(1);}
	72.6% {@include opacity(0);}
	72.7999% {@include opacity(0);}
	72.8% {@include opacity(1);}
	72.999% {@include opacity(1);}
	73% {@include opacity(0);}
	80.3999% {@include opacity(0);}
	80.4% {@include opacity(1);}
	80.5999% {@include opacity(1);}
	80.6% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes askHand-3 {
	0% {@include opacity(0);}
	0.3999% {@include opacity(0);}
	0.4% {@include opacity(1);}
	0.5999% {@include opacity(1);}
	0.6% {@include opacity(0);}
	8.4999% {@include opacity(0);}
	8.5% {@include opacity(1);}
	8.5999% {@include opacity(1);}
	8.6% {@include opacity(0);}
	22.3999% {@include opacity(0);}
	22.4% {@include opacity(1);}
	22.5999% {@include opacity(1);}
	22.6% {@include opacity(0);}
	26.3999% {@include opacity(0);}
	26.4% {@include opacity(1);}
	26.5999% {@include opacity(1);}
	26.6% {@include opacity(0);}
	26.7999% {@include opacity(0);}
	26.8% {@include opacity(1);}
	26.999% {@include opacity(1);}
	27% {@include opacity(0);}
	34.3999% {@include opacity(0);}
	34.4% {@include opacity(1);}
	34.5999% {@include opacity(1);}
	34.6% {@include opacity(0);}
	44.3999% {@include opacity(0);}
	44.4% {@include opacity(1);}
	44.5999% {@include opacity(1);}
	44.6% {@include opacity(0);}
	54.3999% {@include opacity(0);}
	54.4% {@include opacity(1);}
	54.5999% {@include opacity(1);}
	54.6% {@include opacity(0);}
	68.3999% {@include opacity(0);}
	68.4% {@include opacity(1);}	
	68.5999% {@include opacity(1);}
	68.6% {@include opacity(0);}
	72.3999% {@include opacity(0);}
	72.4% {@include opacity(1);}
	72.5999% {@include opacity(1);}
	72.6% {@include opacity(0);}
	72.7999% {@include opacity(0);}
	72.8% {@include opacity(1);}
	72.999% {@include opacity(1);}
	73% {@include opacity(0);}
	80.3999% {@include opacity(0);}
	80.4% {@include opacity(1);}
	80.5999% {@include opacity(1);}
	80.6% {@include opacity(0);}
	100% {@include opacity(0);}
}