@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Popup-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
	background-color: rgba($green, .5);
	@include flex('center','center');
	user-select: none;
}

.Popup {
	position: relative;
	width: 58vw;
	padding: 1.2em 6.2em 2.2em 1.2em;
	text-align: left;	
	color: black;
	background-image: url('../../assets/images/background-popup.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: center center;
	overflow: hidden;
	background-color: transparent;
	z-index: 1;
	@include box-shadow(0,0,0,0, rgba(black, 0));
}

.Popup-body {
	width: 100%;
	height: 100%;
	font-size: 2em;
	overflow: auto;
	&::-webkit-scrollbar{display: none;}
}

.Popup-closeBtn {
	position: absolute;
	right: 2.5em;
	top: 2.5em;
	width: 3.0em;
	height: 3.0em;
	background-image: url('../../assets/images/icon-close.svg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	z-index: 1;
	cursor: pointer;
	&:hover {@include scale(1.01);}
}

.Popup-title {
	font-weight: bold;
}

.Popup-text {
	padding: 0 1em 1em;
	.checkMark{
		display: inline-block;
		width: 1em;
		height: 1em;
		background-image: url('../../assets/images/icon-checkmark.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
	.materialList {
		display: inline-block;
		width: 1em;
		height: 1em;
		background-image: url('../../assets/images/icon-pdf.svg');
		background-repeat: no-repeat;
		background-size: contain;
	}
	.pwaPopup {
		display: inline-block;
		font-weight: bold;
	}
	ul {
		list-style: none;
		padding-left: 1em;
		padding-top: 1em;
	}
	li {
		position: relative;
		padding-bottom: 1em;
		line-height: 1.5em;
		&:before{
			content: '';
			position: absolute;
			background-image: url('../../assets/images/icon-menu-bullet.svg');
			width: 1.5em;
			height: 1.5em;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center center;
			left: -1.7em;
			top: -0.1em;
		}
	}
}

.Popup-buttons {
	width: 100%;
	margin-top: 2em;
	text-align: right;
	.Button {
		&:hover {@include scale(1);}
	}
	&--2 {
		text-align: center;
		.Button {margin: 0 0.5em;}
	}
}

.Popup-printFeedback,
.Popup-emailFeedback {
	margin: 0.25em 0 0.5em 0;
	height: 1em;
	font-size: 0.875em;
	color: $orange;
}

.Popup-email {
	margin-top: 2em;
	padding: 0 1em;
}

.Popup-emailLabel {
	font-weight: 700;
	margin-bottom: 0.5em;
}

.Popup-emailInput {
	display: inline-block;
	vertical-align: top;
	width: 17em;
	margin-right: 1em;
	input {
		width: 100%;
		background-color: white;
		border: 0.1em solid $orange;
		border-radius: 0.25em;
		font-size: 1.25em;
		padding: 0.25em;
	}
}

.Popup-emailBtn {
	display: inline-block;
	vertical-align: top;
}

.Popup.Popup--pwa,
.Popup.Popup--pwaIOS {
	padding: 1em;
	.Popup-body{@include flex('center', 'center');}
	.Popup-text {
		padding: 1em 1.25em;
		.icon {
			display: inline-block;
			vertical-align: center;
			width: 1.5em;
			height: 1.5em;
			background-image: url('../../assets/images/icon-pwa-android.svg');
			background-size: contain;
			background-position: center bottom;
			background-repeat: no-repeat;
		}
		.icon-add {
			display: none;
			vertical-align: center;
			width: 1em;
			height: 1em;
			background-image: url('../../assets/images/icon-pwa-ios-add.svg');
			background-size: contain;
			background-position: center bottom;
			background-repeat: no-repeat;
		}
		ul li {font-size: 1em;}
		ul li:nth-child(3) {
			padding-right: 5em;
			background-image: url('../../assets/images/app-icon.png');
			background-size: 4em auto;
			background-position: center right;
			background-repeat: no-repeat;
		}
	}
}
.Popup.Popup--pwaIOS {
	.Popup-text .icon-add {display: inline-block;}
	.Popup-text .icon {background-image: url('../../assets/images/icon-pwa-ios.svg');}
}


.Popup.Popup--infoPopup {
	padding: 1em;
	.Popup-body{@include flex('center', 'center');}
	.Popup-text {padding: 1em 1.25em;}
}

.Popup.Popup--printActivities,
.Popup.Popup--printStories {
	padding: 1.25em 2.25em;
	&:after {
		content: '';
		position: absolute;
		top: 2em;
		left: 2em;
		width: 8em;
		height: 8em;
		border-radius: 10em;
		background-color: white;
		border: 0.25em solid $green;
		background-image: url('../../assets/images/icon-print.svg');
		background-size: 70% auto;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.Popup-body {
		padding-top: 2em;
	}
	.Popup-buttons {
		text-align: center;
		&--2 {.Button {margin: 0.75em 0;}}
	}
}