@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.LandingPage {
	position: relative;
	height: 100%;
	width: 100%;
	@include flex('center', 'center');	
	@include no-select();
	overflow: hidden;
	background-image: url('../../assets/images/background-1.jpg');
	background-size: cover;
	background-position: bottom center;
	background-repeat: no-repeat;
}

.LandingPage-characters {
	width: 80em;
	height: 55em;
	background-image: url('../../assets/images/characters-splash.png');
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
	&:hover{
		cursor: pointer;
		@include scale(1.01);
	}
}

.LandingPage-plant {
	position: absolute;
	background-repeat: no-repeat;
	background-size: contain;

	&--topRight {
		top: -1em;
		right: 0;
		width: 15em;
		height: 15em;
		background-image: url('../../assets/images/plants/plant1.svg');
		animation: sway1 8s ease-in-out 0s infinite;
	}

	&--topMiddleLeft {
		top: -1em;
		right: 50em;
		width: 5em;
		height: 10em;
		background-image: url('../../assets/images/plants/plant2.svg');
		animation: sway2 7.7s ease-in-out 0s infinite;
	}

	&--topMiddleRight {
		top: -1em;
		right: 46em;
		width: 5em;
		height: 10em;
		background-image: url('../../assets/images/plants/plant3.svg');
		animation: sway1 7.8s ease-in-out 0s infinite;
	}

	&--rightMiddle {
		top: 20em;
		right: -5em;
		width: 18em;
		height: 15em;
		background-image: url('../../assets/images/plants/plant4.svg');
		transform: rotate(100deg);
		animation: sway3 7.8s ease-in-out 0s infinite;
	}

	&--leftTopTop {
		top: 5em;
		left: -2em;
		width: 12em;
		height: 12em;
		background-image: url('../../assets/images/plants/plant5.svg');
		animation: sway4 8.5s ease-in-out 0s infinite;
	}
	&--leftTopBottom {
		top: 10.5em;
		left: -2.5em;
		width: 12em;
		height: 12em;
		background-image: url('../../assets/images/plants/plant6.svg');
		animation: sway4 8.5s ease-in-out 0s infinite;
	}

	&--leftBottom {
		position: absolute;
		bottom: 15em;
		left: -2em;
		width: 12em;
		height: 12em;
		background-image: url('../../assets/images/plants/plant5.svg');
		background-repeat: no-repeat;
		background-size: contain;
		animation: sway5 7.6s ease-in-out 0s infinite;
	}

	&--bottomLeft {
		bottom: -4em;
		right: 35em;
		width: 15em;
		height: 13em;
		background-image: url('../../assets/images/plants/plant7.svg');
		animation: sway6 8.1s ease-in-out 0s infinite;
	}

	&--bottomRight{
		bottom: -10em;
		right: 15em;
		width: 20em;
		height: 20em;
		background-image: url('../../assets/images/plants/plant8.svg');
		animation: sway7 9s ease-in-out 0s infinite;
	}
}

.LandingPage-BNV-logo,
.LandingPage-Nordea-logo {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 7em;
	background-repeat: no-repeat;
	background-size: 25em auto;
	background-position: center center
}
.LandingPage-BNV-logo {background-image: url('../../assets/images/logo-blivnaturligvis-light.svg');}

.LandingPage-Nordea-logo {
	width: 22%;
	height: 9em;
	background-size: 17em auto;
	background-image: url('../../assets/images/logo-nordeafonden.png');
}
