@import '../../../../styles/fonts';
@import '../../../../styles/variables';
@import '../../../../styles/mixins';
@import '../../../../styles/animations';
.DustParticles {
	position: absolute;
	width: 100%;
	height: 100%;
}

.DustParticles-dustParticle {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: top 0 left 0;
	background-size: 100% 100%;
	background-repeat: repeat;
	@include opacity(0.25);
	@include translate3d(0, 0, 0,);
	&--1 {
		background-image: url('../../../../assets/images/animations/shared/dust1.png');
		-webkit-animation: dustParticles1 40s linear 0s infinite;
		animation: dustParticles1 40s linear 0s infinite;
	}
	&--2 {
		background-image: url('../../../../assets/images/animations/shared/dust2.png');
		-webkit-animation: dustParticles2 40s linear 0s infinite;
		animation: dustParticles2 40s linear 0s infinite;
	}
}

@-webkit-keyframes dustParticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 25vh left 25vw;}
	50% {background-position: top 40vh left 50vw;}
	75% {background-position: top 75vh left 65vw;}
	100% {background-position: top 100vh left 100vw;}
}
@keyframes dustParticles1 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 25vh left 25vw;}
	50% {background-position: top 40vh left 50vw;}
	75% {background-position: top 75vh left 65vw;}
	100% {background-position: top 100vh left 100vw;}
}
@-webkit-keyframes dustParticles2 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 50vh left 20vw;}
	50% {background-position: top 100vh left 40vw;}
	75% {background-position: top 150vh left 75vw;}
	100% {background-position: top 200vh left 100vw;}
}
@keyframes dustParticles2 {
	0% {background-position: top 0 left 0;}
	25% {background-position: top 50vh left 20vw;}
	50% {background-position: top 100vh left 40vw;}
	75% {background-position: top 150vh left 75vw;}
	100% {background-position: top 200vh left 100vw;}
}