@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Badges {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../assets/images/badges/rewards/background.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
	background-color: $beige;
	overflow: hidden;
	@include flex('center', 'flex-end');
	&--configure {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}
}

.Badges-animals,
.Badges-animalsGlow,
.Badges-cave,
.Badges-caveGlow,
.Badges-weapons,
.Badges-weaponsGlow,
.Badges-weaponsGlow2,
.Badges-liv,
.Badges-ask,
.Badges-preyGlow,
.Badges-fire,
.Badges-fireGlow,
.Badges-magic,
.Badges-magicGlow,
.Badges-tracks,
.Badges-tracksGlow,
.Badges-hunting,
.Badges-huntingGlow,
.Badges-gatherer,
.Badges-gathererGlow,
.Badges-foreground {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center bottom;
}

.Badges-animals {background-image: url('../../assets/images/badges/rewards/deer.png');}
.Badges-animalsGlow {
	background-image: url('../../assets/images/badges/rewards/deer-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;}
.Badges-cave {background-image: url('../../assets/images/badges/rewards/hut.png');}
.Badges-caveGlow {
	background-image: url('../../assets/images/badges/rewards/hut-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-weapons {background-image: url('../../assets/images/badges/rewards/weapons.png');}
.Badges-weaponsGlow {
	background-image: url('../../assets/images/badges/rewards/weapons-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-weaponsGlow2 {
	background-image: url('../../assets/images/badges/rewards/weapons-glow2.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-ask {
	background-image: url('../../assets/images/badges/rewards/ask.png');
	&--weapons {background-image: url('../../assets/images/badges/rewards/ask-weapons.png');}
	&--prey {background-image: url('../../assets/images/badges/rewards/ask-prey.png');}
}
.Badges-preyGlow {
	background-image: url('../../assets/images/badges/rewards/prey-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-liv {
	background-image: url('../../assets/images/badges/rewards/liv.png');
	&--fire {background-image: url('../../assets/images/badges/rewards/liv-fire.png');}
}
.Badges-fire {background-image: url('../../assets/images/badges/rewards/fire.png');}
.Badges-fireGlow {
	background-image: url('../../assets/images/badges/rewards/fire-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-magic {background-image: url('../../assets/images/badges/rewards/amulet.png');}
.Badges-magicGlow {
	background-image: url('../../assets/images/badges/rewards/amulet-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-tracks {background-image: url('../../assets/images/badges/rewards/tracks.png');}
.Badges-tracksGlow {
	background-image: url('../../assets/images/badges/rewards/tracks-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-hunting {background-image: url('../../assets/images/badges/rewards/hunting.png');}
.Badges-huntingGlow {
	background-image: url('../../assets/images/badges/rewards/hunting-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}
.Badges-gatherer {background-image: url('../../assets/images/badges/rewards/gatherer.png');}
.Badges-gathererGlow {
	background-image: url('../../assets/images/badges/rewards/gatherer-glow.png');
	animation: rewardGlow 3s ease-in-out 0s forwards;
}

.Badges-foreground {background-image: url('../../assets/images/badges/rewards/foreground.png');}


.Badges-infoIcon {
	position: absolute;
	top: 0;
	right: 0;
	width: 8em;
	height: 8.5em;
	background-image: url('../../assets/images/icon-info.svg');
	background-repeat: no-repeat;
	background-size: 4em auto;
	background-position: top 2.25em right 1.5em;
	&:hover {@include scale(1.01);}
}

.Badges-title {
	position: absolute;
	font-weight: 600;
	top: 1.5em;
	left: 3.5em;
	font-size: 3.25em;
	text-align: left;
	font-family: Antropos;
	color: white;
}

.Badges-badgesLeft {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 20em;
	@include flex('center');
	flex-direction: column; 
	text-align: left;
	padding-left: 4em;
}

.Badges-badgesCenter {
	position:absolute;
	// left: 0;
	bottom: 0;
	// width: 100%;
	margin: auto;
	text-align: center;
	padding-bottom: 2em;
}

.Badges-badgesRight {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 20em;
	@include flex('center', 'flex-end');
	flex-direction: column; 
	text-align: right;
	padding-right: 4em;
}


@keyframes rewardGlow {
	0% {@include opacity(1);}
	20% {@include opacity(1);}
	35% {@include opacity(0.5);}
	50% {@include opacity(1);}
	65% {@include opacity(0.5);}
	80% {@include opacity(1);}
	100% {@include opacity(0);}
}