/* Colors */
$beige: #FFF5E2;
$orange: #EB8142;
$orange-light: #FFC744;
$green: #3F6B49;
$green-light: #6D9E36;
$lemon: #CCCE66;
$yellow: #FFD25B;
$grey-light: #F0F0F0;


/* Images */


/* Icons */