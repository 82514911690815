@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700');

// font-family: 'Open Sans', sans-serif;
// 300: light, 400: regular, 600: semiBold, 700: bold


@font-face {
	font-family: Antropos;
	src: url('../assets/fonts/antropos.ttf');
	font-style: normal;
	font-weight: normal;
}