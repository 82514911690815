/* COOKIES / PWA */
@-webkit-keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fadeInCookie {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes slideInPWA {
	0% {height: 0;}
	100% {height: 15.5em;}
}
@keyframes slideInPWA {
	0% {height: 0;}
	100% {height: 15.5em;}
}


/* PLANTS */
@-webkit-keyframes sway1 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(4deg) translateX(-1em);}
	100% {transform: rotate(0deg);}	
}
@keyframes sway1 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(4deg) translateX(-1em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway2 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateX(-1em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway2 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateX(-1em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway3 {
	0% {transform: rotate(100deg);}
	50%{transform: rotate(108deg) translate(-0em, 0.8em);}
	100% {transform: rotate(100deg);}
}
@keyframes sway3 {
	0% {transform: rotate(100deg);}
	50%{transform: rotate(108deg) translate(-0em, 0.8em);}
	100% {transform: rotate(100deg);}
}

@-webkit-keyframes sway4 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(4deg) translateY(1em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway4 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(4deg) translateY(1em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway5 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateY(1em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway5 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateY(1em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway6 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(8deg) translateX(1em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway6 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(8deg) translateX(1em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway7 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(3deg) translateX(0.8em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway7 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(3deg) translateX(0.8em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway8 {
	0% {transform: rotate(-8deg);}
	50% {transform: rotate(-16deg) translateX(-1em);}
	100% {transform: rotate(-8deg);}
}
@keyframes sway8 {
	0% {transform: rotate(-8deg);}
	50% {transform: rotate(-16deg) translateX(-1em);}
	100% {transform: rotate(-8deg);}
}

@-webkit-keyframes sway9 {
	0% {transform: rotate(0deg);}
	50%{transform: rotate(8deg) translate(-0em, -0.8em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway9 {
	0% {transform: rotate(0deg);}
	50%{transform: rotate(8deg) translate(-0em, -0.8em);}
	100% {transform: rotate(0deg);}
}

@-webkit-keyframes sway10 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateX(-1em) translateY(0.4em);}
	100% {transform: rotate(0deg);}
}
@keyframes sway10 {
	0% {transform: rotate(0deg);}
	50% {transform: rotate(6deg) translateX(-1em) translateY(0.4em);}
	100% {transform: rotate(0deg);}
}


/* BADGES */
@-webkit-keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}
@keyframes fade {
	0% {@include opacity(0);}
	100% {@include opacity(1);}
}

@-webkit-keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}
@keyframes bounce {
	0% {@include scale(0.9);}
	10% {@include scale(0.5);}
	25% {@include scale(1);}
	45% {@include scale(0.9);}
	60% {@include scale(1.05);}
	90% {@include scale(1);}
	100% {@include scale(1);}
}


/* MATERIALS ICON */

@-webkit-keyframes shake {
  0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}
@keyframes shake {
	0%,
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-8px);
            transform: translateX(-8px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  80% {
    -webkit-transform: translateX(6px);
            transform: translateX(6px);
  }
  90% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
}