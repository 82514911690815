@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.TasteStoneageFood {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/taste-stoneage-food/background.jpg');
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}

.TasteStoneageFood-rings,
.TasteStoneageFood-ask,
.TasteStoneageFood-askEyes, 
.TasteStoneageFood-livEye {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}

.TasteStoneageFood-rings {
	@include opacity(1);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/taste-stoneage-food/rings.png');
	&--1 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/rings-1.png');
		-webkit-animation: rings-1 5s linear 0s infinite;
		animation: rings-1 5s linear 0s infinite;
	}
	&--2 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/rings-2.png');
		-webkit-animation: rings-2 5s linear 0s infinite;
		animation: rings-2 5s linear 0s infinite;
	}
	&--3 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/rings-3.png');
		-webkit-animation: rings-3 5s linear 0s infinite;
		animation: rings-3 5s linear 0s infinite;
	}
}

.TasteStoneageFood-waterDrops {
	position: absolute;
}

.TasteStoneageFood-waterDrop {
	position: absolute;
	top: 0;
  background: white;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #ffffff 100%);
  height: 15px;
	width: 1px;
	@include opacity(0);
	@include translate3d(0, 0, 0);
	&--1 {
		left: 0;
		-webkit-animation: waterdrop4 20s linear 2s infinite;
		animation: waterdrop4 20s linear 2s infinite;
	}
	&--2 {
		left: 25%;
		-webkit-animation: waterdrop4 20s linear 2.5s infinite;
		animation: waterdrop4 20s linear 2.5s infinite;
	}
	&--3 {
		right: 0;
		-webkit-animation: waterdrop4 20s linear 2.25s infinite;
		animation: waterdrop4 20s linear 2.25s infinite;
	}
}

.TasteStoneageFood-ask {
	@include translate3d(0, 0, 0);
	&--1 {
		@include opacity(1);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/ask-1.png');
		-webkit-animation: askWithDuck-1 20s linear 2s infinite;
		animation: askWithDuck-1 20s linear 2s infinite;
	}
	&--2 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/ask-2.png');
		-webkit-animation: askWithDuck-2 20s linear 2s infinite;
		animation: askWithDuck-2 20s linear 2s infinite;
	}
	&--3 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/taste-stoneage-food/ask-3.png');
		-webkit-animation: askWithDuck-3 20s linear 2s infinite;
		animation: askWithDuck-3 20s linear 2s infinite;
	}
	
}

.TasteStoneageFood-askEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/taste-stoneage-food/ask-eyes.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}
.TasteStoneageFood-livEye {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/taste-stoneage-food/liv-eye.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}

.TasteStoneageFood-waterBlink {
	position: absolute;
	width: 1px;
	height: 0;
	border-radius: 10px;
	margin: -2px 0 0 -2px;
	background-color: rgba(white, 0.5);
	@include translate3d(0, 0, 0);
	@include box-shadow(0, 0, 3px, 3px, white);
	@include opacity(0);
	&--1 {
		-webkit-animation: waterBlink 20s ease-in 2.2s infinite;
		animation: waterBlink 20s ease-in 2.2s infinite;
	}
	&--2 {
		-webkit-animation: waterBlink 20s ease-in 2.7s infinite;
		animation: waterBlink 20s ease-in 2.7s infinite;
	}
	&--3 {
		-webkit-animation: waterBlink 20s ease-in 2.45s infinite;
		animation: waterBlink 20s ease-in 2.45s infinite;
	}
}
@-webkit-keyframes askWithDuck-1 {
	0% {@include opacity(1);}
	0.499% {@include opacity(1);}
	0.5% {@include opacity(0);}
	10.499% {@include opacity(0);}
	10.5% {@include opacity(1);}
	30.499% {@include opacity(1);}
	30.5% {@include opacity(0);}
	40.499% {@include opacity(0);}
	40.5% {@include opacity(1);}
	60.499% {@include opacity(1);}
	60.5% {@include opacity(0);}
	70.499% {@include opacity(0);}
	70.5% {@include opacity(1);}
	100% {@include opacity(1);}
}
@keyframes askWithDuck-1 {
	0% {@include opacity(1);}
	0.499% {@include opacity(1);}
	0.5% {@include opacity(0);}
	10.499% {@include opacity(0);}
	10.5% {@include opacity(1);}
	30.499% {@include opacity(1);}
	30.5% {@include opacity(0);}
	40.499% {@include opacity(0);}
	40.5% {@include opacity(1);}
	60.499% {@include opacity(1);}
	60.5% {@include opacity(0);}
	70.499% {@include opacity(0);}
	70.5% {@include opacity(1);}
	100% {@include opacity(1);}
}

@-webkit-keyframes askWithDuck-2 {
	0% {@include opacity(0);}
	0.499% {@include opacity(0);}
	0.5% {@include opacity(1);}
	0.999% {@include opacity(1);}
	1% {@include opacity(0);}
	9.999% {@include opacity(0);}
	10% {@include opacity(1);}
	10.499% {@include opacity(1);}
	10.5% {@include opacity(0);}
	30.499% {@include opacity(0);}
	30.5% {@include opacity(1);}
	30.999% {@include opacity(1);}
	31% {@include opacity(0);}
	39.999% {@include opacity(0);}
	40% {@include opacity(1);}
	40.499% {@include opacity(1);}
	40.5%  {@include opacity(0);}
	60.499% {@include opacity(0);}
	60.5% {@include opacity(1);}
	60.999% {@include opacity(1);}
	61% {@include opacity(0);}
	69.999% {@include opacity(0);}
	70% {@include opacity(1);}
	70.499% {@include opacity(1);}
	70.5% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes askWithDuck-2 {
	0% {@include opacity(0);}
	0.499% {@include opacity(0);}
	0.5% {@include opacity(1);}
	0.999% {@include opacity(1);}
	1% {@include opacity(0);}
	9.999% {@include opacity(0);}
	10% {@include opacity(1);}
	10.499% {@include opacity(1);}
	10.5% {@include opacity(0);}
	30.499% {@include opacity(0);}
	30.5% {@include opacity(1);}
	30.999% {@include opacity(1);}
	31% {@include opacity(0);}
	39.999% {@include opacity(0);}
	40% {@include opacity(1);}
	40.499% {@include opacity(1);}
	40.5%  {@include opacity(0);}
	60.499% {@include opacity(0);}
	60.5% {@include opacity(1);}
	60.999% {@include opacity(1);}
	61% {@include opacity(0);}
	69.999% {@include opacity(0);}
	70% {@include opacity(1);}
	70.499% {@include opacity(1);}
	70.5% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes askWithDuck-3 {
	0% {@include opacity(0);}
	0.999% {@include opacity(0);}
	1% {@include opacity(1);}
	9.999% {@include opacity(1);}
	10% {@include opacity(0);}
	30.999% {@include opacity(0);}
	31% {@include opacity(1);}
	39.999% {@include opacity(1);}
	40% {@include opacity(0);}
	60.999% {@include opacity(0);}
	61% {@include opacity(1);}
	69.99% {@include opacity(1);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes askWithDuck-3 {
	0% {@include opacity(0);}
	0.999% {@include opacity(0);}
	1% {@include opacity(1);}
	9.999% {@include opacity(1);}
	10% {@include opacity(0);}
	30.999% {@include opacity(0);}
	31% {@include opacity(1);}
	39.999% {@include opacity(1);}
	40% {@include opacity(0);}
	60.999% {@include opacity(0);}
	61% {@include opacity(1);}
	69.99% {@include opacity(1);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}


@-webkit-keyframes waterdrop4 {
	0% {@include opacity(0); @include translate3d(0, 0, 0);}
	0.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	1% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	3% {@include opacity(0); @include translate3d(0, 800%, 0);}
	30.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	31% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	33% {@include opacity(0); @include translate3d(0, 800%, 0);}
	60.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	61% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	63% {@include opacity(0); @include translate3d(0, 800%, 0);}
	100% {@include opacity(0); @include translate3d(0, 0, 0);}	
}
@keyframes waterdrop4 {
	0% {@include opacity(0); @include translate3d(0, 0, 0);}
	0.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	1% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	3% {@include opacity(0); @include translate3d(0, 800%, 0);}
	30.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	31% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	33% {@include opacity(0); @include translate3d(0, 800%, 0);}
	60.999% {@include opacity(0); @include translate3d(0, 0, 0);}
	61% {@include opacity(0.8); @include translate3d(0, 0, 0);}
	63% {@include opacity(0); @include translate3d(0, 800%, 0);}
	100% {@include opacity(0); @include translate3d(0, 0, 0);}	
}

@-webkit-keyframes waterBlink {
	0% {@include opacity(0);}
	1% {@include opacity(0);}
	3% {@include opacity(0.8);}
	4% {@include opacity(0);}
	31% {@include opacity(0);}
	33% {@include opacity(0.8);}
	34% {@include opacity(0);}
	61% {@include opacity(0);}
	63% {@include opacity(0.8);}
	64% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes waterBlink {
	0% {@include opacity(0);}
	1% {@include opacity(0);}
	3% {@include opacity(0.8);}
	4% {@include opacity(0);}
	31% {@include opacity(0);}
	33% {@include opacity(0.8);}
	34% {@include opacity(0);}
	61% {@include opacity(0);}
	63% {@include opacity(0.8);}
	64% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes rings-1 {
	0% {@include opacity(0);}
	22% {@include opacity(0);}
	24% {@include opacity(0.25);}
	26% {@include opacity(0);}
	62% {@include opacity(0);}
	64% {@include opacity(0.25);}
	66% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes rings-1 {
	0% {@include opacity(0);}
	22% {@include opacity(0);}
	24% {@include opacity(0.25);}
	26% {@include opacity(0);}
	62% {@include opacity(0);}
	64% {@include opacity(0.25);}
	66% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes rings-2 {
	0% {@include opacity(0);}
	24% {@include opacity(0);}
	26% {@include opacity(0.25);}
	28% {@include opacity(0);}
	64% {@include opacity(0);}
	66% {@include opacity(0.25);}
	68% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes rings-2 {
	0% {@include opacity(0);}
	24% {@include opacity(0);}
	26% {@include opacity(0.25);}
	28% {@include opacity(0);}
	64% {@include opacity(0);}
	66% {@include opacity(0.25);}
	68% {@include opacity(0);}
	100% {@include opacity(0);}
}
@-webkit-keyframes rings-3 {
	0% {@include opacity(0);}
	26% {@include opacity(0);}
	28% {@include opacity(0.25);}
	30% {@include opacity(0);}
	66% {@include opacity(0);}
	68% {@include opacity(0.25);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes rings-3 {
	0% {@include opacity(0);}
	26% {@include opacity(0);}
	28% {@include opacity(0.25);}
	30% {@include opacity(0);}
	66% {@include opacity(0);}
	68% {@include opacity(0.25);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}
