@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.MoveLikeAHunter {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/move-like-a-hunter/background.jpg');
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}

.MoveLikeAHunter-deer,
.MoveLikeAHunter-deerEar,
.MoveLikeAHunter-deerNose,
.MoveLikeAHunter-deerEyes,
.MoveLikeAHunter-sunbeam,
.MoveLikeAHunter-foreground1 {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: center top;
	background-size: cover;
	background-repeat: no-repeat;
}

.MoveLikeAHunter-deer {
	background-image: url('../../../assets/images/animations/move-like-a-hunter/deer.png');
}

.MoveLikeAHunter-deerNose {
	background-image: url('../../../assets/images/animations/move-like-a-hunter/deer-nose.png');
	@include translate3d(0, 0, 0);
	-webkit-animation: deerNose 10s linear 2s infinite;
	animation: deerNose 10s linear 2s infinite;
}

.MoveLikeAHunter-deerEar {
	@include translateAndRotate(0, 0, 0, 0deg);
	background-image: url('../../../assets/images/animations/move-like-a-hunter/deer-ear.png');
	-webkit-animation: deerEar 8s linear 1s infinite;
	animation: deerEar 8s linear 1s infinite;
}

.MoveLikeAHunter-deerEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/move-like-a-hunter/deer-eyes.png');
	-webkit-animation: eye-blink 8s linear 3s infinite;
	animation: eye-blink 8s linear 3s infinite;
}

.MoveLikeAHunter-sunbeam {
	@include translate3d(0, 0, 0,);
	&--1, &--2, &--3, &--4, &--5, &--6 {top: -2em;}
	&--1 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur5.png');}
	&--2 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur3.png');}
	&--3 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur1.png');}
	&--4 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur2.png');}
	&--5 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur1.png');}
	&--6 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur4.png');}
	
	&--1 {
		@include opacity(1);
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		left: 2em;
	}
	&--2 {
		@include opacity(0);
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 5s infinite;
		left: -2em;
	}
	&--3 {
		@include opacity(0);
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		left: -5em;
	}
	&--4 {
		@include opacity(1);
		left: 4em;
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
	}
	&--5 {
		@include opacity(0);
		left: -3em;
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 5s infinite;
	
	}
	&--6 {
		@include opacity(0);
		left: 0em;
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
	}
}

.MoveLikeAHunter-foreground1 {
	background-image: url('../../../assets/images/animations/move-like-a-hunter/foreground1.png');
}

.MoveLikeAHunter-foreground2,
.MoveLikeAHunter-liv,
.MoveLikeAHunter-arrow {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: center bottom;
	background-size: cover;
	background-repeat: no-repeat;
	@include translate3d(0, 0, 0);
}

.MoveLikeAHunter-foreground2 {
	background-image: url('../../../assets/images/animations/move-like-a-hunter/foreground2.png');
}

.MoveLikeAHunter-liv {
	background-image: url('../../../assets/images/animations/move-like-a-hunter/liv.png');
}

.MoveLikeAHunter-arrow {
	@include translate3d(0, 0, 0);
	&--1 {
		@include opacity(1);
		background-image: url('../../../assets/images/animations/move-like-a-hunter/arrow1.png');
		-webkit-animation: arrow-1 20s linear 2s infinite;
		animation: arrow-1 20s linear 2s infinite;
	}
	&--2 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/move-like-a-hunter/arrow2.png');
		-webkit-animation: arrow-2 20s linear 2s infinite;
		animation: arrow-2 20s linear 2s infinite;
	}
	&--3 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/move-like-a-hunter/arrow3.png');
		-webkit-animation: arrow-3 20s linear 2s infinite;
		animation: arrow-3 20s linear 2s infinite;
	}
	&--4 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/move-like-a-hunter/arrow4.png');
		-webkit-animation: arrow-4 20s linear 2s infinite;
		animation: arrow-4 20s linear 2s infinite;
	}
}

@-webkit-keyframes arrow-1 {
	0% {@include opacity(1);}
	4.999% {@include opacity(1);}
	5% {@include opacity(0);}
	21.999% {@include opacity(0);}
	22% {@include opacity(1);}
	64.999% {@include opacity(1);}
	65% {@include opacity(0);}
	81.999% {@include opacity(0);}
	82% {@include opacity(1);}
	100% {@include opacity(1);}
}
@keyframes arrow-1 {
	0% {@include opacity(1);}
	4.999% {@include opacity(1);}
	5% {@include opacity(0);}
	21.999% {@include opacity(0);}
	22% {@include opacity(1);}
	64.999% {@include opacity(1);}
	65% {@include opacity(0);}
	81.999% {@include opacity(0);}
	82% {@include opacity(1);}
	100% {@include opacity(1);}
}
@-webkit-keyframes arrow-2 {
	0% {@include opacity(0);}
	4.999% {@include opacity(0);}
	5% {@include opacity(1);}
	5.999% {@include opacity(1);}
	6% {@include opacity(0);}
	20.999% {@include opacity(0);}
	21% {@include opacity(1);}
	21.999% {@include opacity(1);}
	22% {@include opacity(0);}
	64.999% {@include opacity(0);}
	65% {@include opacity(1);}
	65.999% {@include opacity(1);}
	66% {@include opacity(0);}
	80.999% {@include opacity(0);}
	81% {@include opacity(1);}
	81.999% {@include opacity(1);}
	82% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes arrow-2 {
	0% {@include opacity(0);}
	4.999% {@include opacity(0);}
	5% {@include opacity(1);}
	5.999% {@include opacity(1);}
	6% {@include opacity(0);}
	20.999% {@include opacity(0);}
	21% {@include opacity(1);}
	21.999% {@include opacity(1);}
	22% {@include opacity(0);}
	64.999% {@include opacity(0);}
	65% {@include opacity(1);}
	65.999% {@include opacity(1);}
	66% {@include opacity(0);}
	80.999% {@include opacity(0);}
	81% {@include opacity(1);}
	81.999% {@include opacity(1);}
	82% {@include opacity(0);}
	100% {@include opacity(0);}
}
@-webkit-keyframes arrow-3 {
	0% {@include opacity(0);}
	5.999% {@include opacity(1);}
	6% {@include opacity(1);}
	6.999% {@include opacity(1);}
	7% {@include opacity(0);}
	19.999% {@include opacity(0);}
	20% {@include opacity(1);}
	20.999%  {@include opacity(1);}
	21% {@include opacity(0);}
	65.999% {@include opacity(0);}
	66% {@include opacity(1);}
	66.999% {@include opacity(1);}
	67% {@include opacity(0);}
	79.999% {@include opacity(0);}
	80% {@include opacity(1);}
	80.999% {@include opacity(1);}
	81% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes arrow-3 {
	0% {@include opacity(0);}
	5.999% {@include opacity(1);}
	6% {@include opacity(1);}
	6.999% {@include opacity(1);}
	7% {@include opacity(0);}
	19.999% {@include opacity(0);}
	20% {@include opacity(1);}
	20.999%  {@include opacity(1);}
	21% {@include opacity(0);}
	65.999% {@include opacity(0);}
	66% {@include opacity(1);}
	66.999% {@include opacity(1);}
	67% {@include opacity(0);}
	79.999% {@include opacity(0);}
	80% {@include opacity(1);}
	80.999% {@include opacity(1);}
	81% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes arrow-4 {
	0% {@include opacity(0);}
	6.999% {@include opacity(0);}
	7% {@include opacity(1);}
	19.999% {@include opacity(1);}
	20% {@include opacity(0);}
	66.999% {@include opacity(0);}
	67% {@include opacity(1);}
	79.999% {@include opacity(1);}
	80% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes arrow-4 {
	0% {@include opacity(0);}
	6.999% {@include opacity(0);}
	7% {@include opacity(1);}
	19.999% {@include opacity(1);}
	20% {@include opacity(0);}
	66.999% {@include opacity(0);}
	67% {@include opacity(1);}
	79.999% {@include opacity(1);}
	80% {@include opacity(0);}
	100% {@include opacity(0);}
}


@-webkit-keyframes deerEar {
	0% {@include rotate(0deg);}
	3% {@include rotate(0deg);}
	3.5% {@include rotate(-15deg);}
	4% {@include rotate(0deg);}
	55% {@include rotate(0deg);}
	55.5% {@include rotate(-5deg);}
	56% {@include rotate(0deg);}
	70% {@include rotate(0deg);}
	70.5% {@include rotate(-10deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes deerEar {
	0% {@include rotate(0deg);}
	3% {@include rotate(0deg);}
	3.5% {@include rotate(-15deg);}
	4% {@include rotate(0deg);}
	55% {@include rotate(0deg);}
	55.5% {@include rotate(-5deg);}
	56% {@include rotate(0deg);}
	70% {@include rotate(0deg);}
	70.5% {@include rotate(-10deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes deerNose {
	0%{@include translate3d(0, 0, 0);}
	9.999%{@include translate3d(0, 0, 0);}
	10% {@include translate3d(0, -1px, 0);}
	10.499% {@include translate3d(0, -1px, 0);}
	10.5% {@include translate3d(0, 0, 0);}
	39.999%{@include translate3d(0, 0, 0);}
	40% {@include translate3d(0, -1px, 0);}
	40.499% {@include translate3d(0, -1px, 0);}
	40.5% {@include translate3d(0, 0, 0);}
	69.999%{@include translate3d(0, 0, 0);}
	70% {@include translate3d(0, -1px, 0);}
	70.499% {@include translate3d(0, -1px, 0);}
	70.5% {@include translate3d(0, 0, 0);}	
	100%{@include translate3d(0, 0, 0);}
}
@keyframes deerNose {
	0%{@include translate3d(0, 0, 0);}
	9.999%{@include translate3d(0, 0, 0);}
	10% {@include translate3d(0, -1px, 0);}
	10.499% {@include translate3d(0, -1px, 0);}
	10.5% {@include translate3d(0, 0, 0);}
	39.999%{@include translate3d(0, 0, 0);}
	40% {@include translate3d(0, -1px, 0);}
	40.499% {@include translate3d(0, -1px, 0);}
	40.5% {@include translate3d(0, 0, 0);}
	69.999%{@include translate3d(0, 0, 0);}
	70% {@include translate3d(0, -1px, 0);}
	70.499% {@include translate3d(0, -1px, 0);}
	70.5% {@include translate3d(0, 0, 0);}	
	100%{@include translate3d(0, 0, 0);}
}