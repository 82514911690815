@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.About {
	height: 100%;
	background-color: $beige;
	text-align: left;
	overflow: auto;
	@include no-select();
}

.About-body {
	padding: 7.5em 12.75em 2em 12.75em;
}

.About-text {
	font-size: 1.875em;
	h2 {
		font-size: 1.833em;
		font-family: Antropos;
		color: $green;
		margin-top: 0;
	}
	a {
		color: black;
		text-decoration: underline;
		&:visited {color: black;}
	}
}

.About-logo {
	width: 100%;
	height: 5em;
	margin-top: 5em;
	background-image: url('../../assets/images/logo-blivnaturligvis.svg');
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;
}