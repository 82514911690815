@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.LightAFire {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/light-a-fire/ask-and-liv.png'), url('../../../assets/images/animations/light-a-fire/background.jpg');
	background-position: center center, center center;
	background-size: cover, cover;
	background-repeat: no-repeat, no-repeat;
}

.LightAFire-drop {
	position: absolute;
  background: white;
  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, #ffffff 100%);
  height: 3em;
	width: 1px;
	@include opacity(0);
	@include translate3d(0, 0, 0,);
	&--1 {
		-webkit-animation: waterdrop1 4s ease-in-out 0s infinite;
		animation: waterdrop1 4s ease-in-out 0s infinite;
	}
	&--2 {
		-webkit-animation: waterdrop2 3.5s ease-in-out 2s infinite;
		animation: waterdrop2 3.5s ease-in-out 2s infinite;
	}
	&--3 {
		-webkit-animation: waterdrop3 6s ease-in-out 1s infinite;
		animation: waterdrop3 6s ease-in-out 1s infinite;
	}
	&--4 {
		-webkit-animation: waterdrop3 6s ease-in-out 1.1s infinite;
		animation: waterdrop3 6s ease-in-out 1.1s infinite;
	}
}

.LightAFire-characters {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	@include translate3d(0, 0, 0,);
	background-image: url('../../../assets/images/animations/light-a-fire/ask-and-liv.png');

	&--dark {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/light-a-fire/ask-and-liv-dark.png');
		-webkit-animation: character-glow-dark 10s ease-in-out 0s infinite;
		animation: character-glow-dark 10s ease-in-out 0s infinite;
	}
	&--bright {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/light-a-fire/ask-and-liv-bright.png');
		-webkit-animation: character-glow-bright 10s ease-in-out 0s infinite;
		animation: character-glow-bright 10s ease-in-out 0s infinite;
	}
}

.LightAFire-fire {
	display: block;
	position: absolute;
	width: 5em;
	height: 6em;
	margin-left: -2.5em;
	margin-top: -3em;
}
.LightAFire-glow {
	position: absolute;
	&--1 {
		top: calc(50% - 3.25em);
		left: calc(50% - 3.25em);
		width: 6.5em;
		height: 7.5em;
		border-radius: 5.5em;
		background-color: #f8fa92;
		@include opacity(1);
		@include blur(2em);
		@include translate3d(0, 0, 0,);
		-webkit-animation: glow 10s ease-in-out 0s infinite;
		animation: glow 10s ease-in-out 0s infinite;
		z-index: 1;
	}
	&--2 {
		top: calc(50% - 2.5em);
		left: calc(50% - 2em);
		width: 4em;
		height: 5em;
		border-radius: 4.5em;
		background-color: #d35400;
		@include opacity(0.5);
		@include box-shadow(0, 0, 5em, 0, #e69d6c);
		@include blur(0.5em);
		@include translate3d(0, 0, 0,);
		-webkit-animation: glow 10s ease-in-out 0s infinite;
		animation: glow 10s ease-in-out 0s infinite;
		z-index: 2;
	}
	&--3 {
		top: 3.5em;
		left: calc(50% - 0.5em);
		width: 1em;
		height: 1em;
		border-radius: 2.5em;
		background-color: #f0f342;
		@include opacity(0.25);
		@include box-shadow(0, 0, 5em, 0, #f2f3ab);
		@include blur(0.3em);
		@include translate3d(0, 0, 0,);
		-webkit-animation: glow 5s ease-in-out 0s infinite backwards;
		animation: glow 5s ease-in-out 0s infinite backwards;
		z-index: 3;
	}
}
.LightAFire-spark {
	position: absolute;
	bottom: 3em;
	left: calc(50% - 2em);
	width: 2em;
	height: 2em;
	@include opacity(0);
	@include translateAndRotate(0, 0, 0, 0deg);
	&--1 {
		-webkit-animation: spark1 2s linear 2s infinite;
		animation: spark1 2s linear 2s infinite;
	}
	&--2 {
		-webkit-animation: spark2 3s linear 1.5s infinite;
		animation: spark2 3s linear 1.5s infinite;
		left: calc(50% - 4em);
	}
	&--3 {
		-webkit-animation: spark3 8s linear 3s infinite;
		animation: spark3 8s linear 3s infinite;
		left: calc(50% - 3em);
	}
	&--4 {
		-webkit-animation: spark4 4s linear 2s infinite;
		animation: spark4 4s linear 2s infinite;
		left: 50%;
	}
	&--5 {
		-webkit-animation: spark5 5s linear 1s infinite;
		animation: spark5 5s linear 1s infinite;
		left: calc(50% - 2em);
	}
	&:after {
		content: '';
		position: absolute;
		top: 2em;
		left: 2em;
		width: 1px;
		height: 1px;
		border-radius: 3px;
		background-color: #f19e66;
		border: 2px solid #d35400;
		@include box-shadow(0, 0, 10px, 0, #d35400);
		@include blur(1	px);
	}
}

@-webkit-keyframes waterdrop1 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	15% {@include opacity(0);}
	30% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}
}
@keyframes waterdrop1 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	15% {@include opacity(0);}
	30% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes waterdrop2 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	20% {@include opacity(0);}
	40% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}
}
@keyframes waterdrop2 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	20% {@include opacity(0);}
	40% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes waterdrop3 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	10% {@include opacity(0);}
	20% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}	
}
@keyframes waterdrop3 {
	0% {@include opacity(0);}
	1% {@include opacity(0.8);}
	10% {@include opacity(0);}
	20% {@include opacity(0); @include translate3d(0, 100vh, 0);}
	100% {@include opacity(0);}	
}



@-webkit-keyframes spark1 {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -10em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -15em, 0, 60deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -20em, 0, 75deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -25em, 0, 90deg);}
}
@keyframes spark1 {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg);}
  40% {@include opacity(1); @include translateAndRotate(0, -10em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -15em, 0, 60deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -20em, 0, 75deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -25em, 0, 90deg);}
}

@-webkit-keyframes spark2 {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
  40% {@include opacity(1); @include translateAndRotate(0, -10em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 30deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -20em, 0, 15deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -25em, 0, 0deg);}
}
@keyframes spark2 {
  0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
  40% {@include opacity(1); @include translateAndRotate(0, -10em, 0, 45deg);}
	60% {@include opacity(1); @include translateAndRotate(0, -16em, 0, 30deg);}
	80% {@include opacity(0.8); @include translateAndRotate(0, -20em, 0, 15deg);}
  100% {@include opacity(0); @include translateAndRotate(0, -25em, 0, 0deg);}
}

@-webkit-keyframes spark3 {
  0% {@include opacity(0); @include translateAndRotate(0, 0em, 0, 0deg)}
  10% {@include opacity(1); @include translateAndRotate(0.5em, -10em, 0, 45deg);}
	15% {@include opacity(1); @include translateAndRotate(-1em, -15em, 0, 35deg);}
	20% {@include opacity(0.8); @include translateAndRotate(1.5em, -20em, 0, 25deg);}
	25% {@include opacity(0); @include translateAndRotate(2em, -25em, 0, 15deg);}
	100% {@include opacity(0); @include translateAndRotate(2em, -25em, 0, 0deg);}
}
@keyframes spark3 {
  0% {@include opacity(0); @include translateAndRotate(0, 0em, 0, 0deg)}
  10% {@include opacity(1); @include translateAndRotate(0.5em, -10em, 0, 45deg);}
	15% {@include opacity(1); @include translateAndRotate(-1em, -15em, 0, 35deg);}
	20% {@include opacity(0.8); @include translateAndRotate(1.5em, -20em, 0, 25deg);}
	25% {@include opacity(0); @include translateAndRotate(2em, -25em, 0, 15deg);}
	100% {@include opacity(0); @include translateAndRotate(2em, -25em, 0, 0deg);}
}

@-webkit-keyframes spark4 {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
	40% {@include opacity(1); @include translateAndRotate(1em, -8em, 0, 45deg);}
	80% {@include opacity(0.8); @include translateAndRotate(2em, -16em, 0, -45deg);}
	100% {@include opacity(0); @include translateAndRotate(2.5em, -20em, 0, -10deg);}
}
@keyframes spark4 {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
	40% {@include opacity(1); @include translateAndRotate(1em, -8em, 0, 45deg);}
	80% {@include opacity(0.8); @include translateAndRotate(2em, -16em, 0, -45deg);}
	100% {@include opacity(0); @include translateAndRotate(2.5em, -20em, 0, -10deg);}
}

@-webkit-keyframes spark5 {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
	40% {@include opacity(1); @include translateAndRotate(-1em, -10em, 0, 90deg);}
	80% {@include opacity(0.8); @include translateAndRotate(-2em, -20em, 0, 110deg);}
	100% {@include opacity(0); @include translateAndRotate(-2.5em, -25em, 0, 130deg);}
}
@keyframes spark5 {
	0% {@include opacity(0); @include translateAndRotate(0, 0, 0, 45deg)}
	40% {@include opacity(1); @include translateAndRotate(-1em, -10em, 0, 90deg);}
	80% {@include opacity(0.8); @include translateAndRotate(-2em, -20em, 0, 110deg);}
	100% {@include opacity(0); @include translateAndRotate(-2.5em, -25em, 0, 130deg);}
}