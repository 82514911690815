@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.FindAnimalTracks {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/find-animal-tracks/background.jpg');
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
}

.FindAnimalTracks-trackHighlight {
	position: absolute;
	width: 0.125em;
	height: 0.125em;
	border-radius: 10em;
	margin: -0.075em 0 0 -0.075em;
	background-color: white;
	@include box-shadow(0, 0, 0.2em, 0.2em, white);
	@include opacity(0);
	@include translate3d(0, 0, 0,);
	&--1 {
		width: 0.7em;
		height: 0.7em;
		margin: -0.35em 0 0 -0.35em;
		-webkit-animation: track-highlights 10s linear 0s infinite;
		animation: track-highlights 10s linear 0s infinite;
	}
	&--2 {
		width: 0.4em;
		height: 0.4em;
		margin: -0.2em 0 0 -0.2em;
		-webkit-animation: track-highlights 10s linear 0.05s infinite;
		animation: track-highlights 10s linear 0.05s infinite;
	}
	&--3 {
		-webkit-animation: track-highlights 10s linear 0.1s infinite;
		animation: track-highlights 10s linear 0.1s infinite;

	}
	&--4 {
		width: 0.4em;
		height: 0.4em;
		margin: -0.2em 0 0 -0.2em;
		-webkit-animation: track-highlights 10s linear 0.2s infinite;
		animation: track-highlights 10s linear 0.2s infinite;
	}
	&--5 {
		width: 0.05em;
		height: 1em;
		margin: -1em 0 0 -0.2em;
		background-color: rgba(white, 0.75);
		@include box-shadow(0, 0, 0.2em, 0.05em, white);
		@include translateAndRotate(0, 0, 0, 35deg);
		-webkit-animation: track-highlights 10s linear 0.4s infinite;
		animation: track-highlights 10s linear 0.4s infinite;
	}
	&--6 {
		width: 0.05em;
		height: 2em;
		margin: -0.5em 0 0 -0.2em;
		background-color: rgba(white, 0.75);
		@include box-shadow(0, 0, 0.2em, 0.05em, white);
		@include translateAndRotate(0, 0, 0, 40deg);
		-webkit-animation: track-highlights 10s linear 0.55s infinite;
		animation: track-highlights 10s linear 0.55s infinite;
	}
	&--7 {
		width: 0.05em;
		height: 1em;
		margin: -0.2em 0 0 -0.1em;
		background-color: rgba(white, 0.75);
		@include box-shadow(0, 0, 0.2em, 0.05em, white);
		-webkit-animation: track-highlights 10s linear 0.7s infinite;
		animation: track-highlights 10s linear 0.7s infinite;
	}
}

.FindAnimalTracks-shadow,
.FindAnimalTracks-leftFoot,
.FindAnimalTracks-rightFoot {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: bottom right;
	background-size: cover;
	background-repeat: no-repeat;
	@include translate3d(0, 0, 0,);
}

.FindAnimalTracks-shadow {
	background-image: url('../../../assets/images/animations/find-animal-tracks/shadow.png');
}

.FindAnimalTracks-leftFoot {
	background-image: url('../../../assets/images/animations/find-animal-tracks/foot-left.png');
	-webkit-animation: leftFoot 20s linear 3s infinite;
	animation: leftFoot 20s linear 3s infinite;
}

.FindAnimalTracks-rightFoot {
	background-image: url('../../../assets/images/animations/find-animal-tracks/foot-right.png');
	-webkit-animation: rightFoot 20s linear 3s infinite;
	animation: rightFoot 20s linear 3s infinite;
}

@-webkit-keyframes leftFoot {
	0% {@include rotate(0deg);}
	40% {@include rotate(0deg);}
	42% {@include rotate(-1deg);}
	60% {@include rotate(-1deg);}
	61% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes leftFoot {
	0% {@include rotate(0deg);}
	40% {@include rotate(0deg);}
	42% {@include rotate(-1deg);}
	60% {@include rotate(-1deg);}
	61% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes rightFoot {
	0% {@include rotate(0deg);}
	2% {@include rotate(0deg);}
	4% {@include rotate(1deg);}
	10% {@include rotate(1deg);}
	13% {@include rotate(0.5deg);}
	16% {@include rotate(1deg);}
	18% {@include rotate(0deg);}
	34% {@include rotate(0deg);}
	36% {@include rotate(1deg);}
	55% {@include rotate(1deg);}
	57% {@include rotate(0deg);}
	75% {@include rotate(0deg);}
	76% {@include rotate(0.5deg);}
	86% {@include rotate(0.5deg);}
	87% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes rightFoot {
	0% {@include rotate(0deg);}
	2% {@include rotate(0deg);}
	4% {@include rotate(1deg);}
	10% {@include rotate(1deg);}
	13% {@include rotate(0.5deg);}
	16% {@include rotate(1deg);}
	18% {@include rotate(0deg);}
	34% {@include rotate(0deg);}
	36% {@include rotate(1deg);}
	55% {@include rotate(1deg);}
	57% {@include rotate(0deg);}
	75% {@include rotate(0deg);}
	76% {@include rotate(0.5deg);}
	86% {@include rotate(0.5deg);}
	87% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes track-highlights {
	0% {@include opacity(0);}
	10% {@include opacity(0);}
	12% {@include opacity(1);;}
	14% {@include opacity(0);}
	30% {@include opacity(0);}
	32% {@include opacity(1);;}
	34% {@include opacity(0);}
	70% {@include opacity(0);}
	72% {@include opacity(1);;}
	74% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes track-highlights {
	0% {@include opacity(0);}
	10% {@include opacity(0);}
	12% {@include opacity(1);;}
	14% {@include opacity(0);}
	30% {@include opacity(0);}
	32% {@include opacity(1);;}
	34% {@include opacity(0);}
	70% {@include opacity(0);}
	72% {@include opacity(1);;}
	74% {@include opacity(0);}
	100% {@include opacity(0);}
}