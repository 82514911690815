@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.MakeAWeapon {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/make-a-weapon/background.jpg');
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
}

.MakeAWeapon-askArm,
.MakeAWeapon-askEyes, 
.MakeAWeapon-livEyes {
	position: absolute;
	width: 100%;
	height: 100%;
	background-position: bottom center;
	background-size: cover;
	background-repeat: no-repeat;
}

.MakeAWeapon-askArm {
	@include translateAndRotate(0, 0, 0, 0deg);
	background-image: url('../../../assets/images/animations/make-a-weapon/ask-arm-1.png');
	-webkit-animation: askArm 20s linear 2s infinite;
	animation: askArm 20s linear 2s infinite;
}

.MakeAWeapon-askEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/make-a-weapon/ask-eyes.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}
.MakeAWeapon-livEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/make-a-weapon/liv-eyes.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}


.MakeAWeapon-dew {
	position: absolute;
	width: 2px;
	height: 2px;
	border-radius: 10px;
	margin: -1px 0 0 -1px;
	background-color: white;
	@include box-shadow(0, 0, 2px, 0, white);
	@include translate3d(0, 0, 0);
	@include opacity(0);
	&--1 {
		-webkit-animation: dew1 5s ease-in 0s infinite;
		animation: dew1 5s ease-in 0s infinite;
	}
	&--2 {
		-webkit-animation: dew2 5s ease-in 2s infinite;
		animation: dew2 5s ease-in 2s infinite;
	}
	&--3 {
		-webkit-animation: dew1 5s ease-in 1s infinite;
		animation: dew1 5s ease-in 1s infinite;
		width: 3px;
		height: 3px;
		margin: -2px 0 0 -2px;
	}
	&--4 {
		-webkit-animation: dew2 5s ease-in 3s infinite;
		animation: dew2 5s ease-in 3s infinite;
		width: 4px;
		height: 4px;
		margin: -2px 0 0 -2px;
	}
	&--5 {
		-webkit-animation: dew3 5s ease-in 0s infinite;
		animation: dew3 5s ease-in 0s infinite;
		width: 3px;
		height: 3px;
		margin: -1px 0 0 -1px;
	}
	&--6 {
		-webkit-animation: dew3 8s ease-in 2s infinite;
		animation: dew3 8s ease-in 2s infinite;
		width: 3px;
		height: 3px;
		margin: -2px 0 0 -2px;
	}
	&--7 {
		-webkit-animation: dew1 5s ease-in 3s infinite;
		animation: dew1 5s ease-in 3s infinite;
		width: 4px;
		height: 3px;
		margin: -2px 0 0 -2px;
	}
	&--8 {
		-webkit-animation: dew2 5s ease-in 0s infinite;
		animation: dew2 5s ease-in 0s infinite;
	}
	&--9 {
		-webkit-animation: dew3 10s ease-in 2s infinite;
		animation: dew3 10s ease-in 2s infinite;
	}
	&--10 {
		-webkit-animation: dew1 5s ease-in 0s infinite;
		animation: dew1 5s ease-in 0s infinite;
		width: 3px;
		height: 3px;
	}
}



/* Ask's arm */
@-webkit-keyframes askArm {
	0% {@include rotate(0deg);}
	0.5% {@include rotate(-8deg);}
	10% {@include rotate(-8deg);}
	11% {@include rotate(0deg);}
	30% {@include rotate(0deg);}
	30.5% {@include rotate(-8deg);}
	40% {@include rotate(-8deg);}
	41% {@include rotate(0deg);}
	60% {@include rotate(0deg);}
	60.5% {@include rotate(-8deg);}
	70% {@include rotate(-8deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes askArm {
	0% {@include rotate(0deg);}
	0.5% {@include rotate(-8deg);}
	10% {@include rotate(-8deg);}
	11% {@include rotate(0deg);}
	30% {@include rotate(0deg);}
	30.5% {@include rotate(-8deg);}
	40% {@include rotate(-8deg);}
	41% {@include rotate(0deg);}
	60% {@include rotate(0deg);}
	60.5% {@include rotate(-8deg);}
	70% {@include rotate(-8deg);}
	71% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

/* Dew */
@-webkit-keyframes dew1 {
	0% {@include opacity(0);}
	10% {@include opacity(1);}
	15% {@include opacity(0);}
	55% {@include opacity(0);}
	60% {@include opacity(1);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes dew1 {
	0% {@include opacity(0);}
	10% {@include opacity(1);}
	15% {@include opacity(0);}
	55% {@include opacity(0);}
	60% {@include opacity(1);}
	70% {@include opacity(0);}
	100% {@include opacity(0);}
}
@-webkit-keyframes dew2 {
	0% {@include opacity(0);}
	30% {@include opacity(1);}
	35% {@include opacity(0);}
	60% {@include opacity(0);}
	70% {@include opacity(1);}
	75% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes dew2 {
	0% {@include opacity(0);}
	30% {@include opacity(1);}
	35% {@include opacity(0);}
	60% {@include opacity(0);}
	70% {@include opacity(1);}
	75% {@include opacity(0);}
	100% {@include opacity(0);}
}
@-webkit-keyframes dew3 {
	0% {@include opacity(0);}
	20% {@include opacity(1);}
	25% {@include opacity(0);}
	50% {@include opacity(0);}
	55% {@include opacity(1);}
	65% {@include opacity(0);}
	75% {@include opacity(0);}
	80% {@include opacity(1);}
	85% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes dew3 {
	0% {@include opacity(0);}
	20% {@include opacity(1);}
	25% {@include opacity(0);}
	50% {@include opacity(0);}
	55% {@include opacity(1);}
	65% {@include opacity(0);}
	75% {@include opacity(0);}
	80% {@include opacity(1);}
	85% {@include opacity(0);}
	100% {@include opacity(0);}
}