@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Badge {
	display: inline-block;
	width: 10em;
	height: 10em;
	background-repeat: no-repeat;
	background-size: contain;
	margin: 1.5em 0;
	@include no-select();
	cursor: pointer;
	&:hover{@include scale(1.01);}
	&--animate {
		animation: fade 2s linear 1 alternate none;
		animation: bounce 1s ease 1 alternate none;	
	}
	&--cave {
		margin-left: 2.5em;
		background-image: url('../../assets/images/badges/cave-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/cave-active.png');}
	}
	&--weapons {
		margin-right: 2.5em;
		background-image: url('../../assets/images/badges/weapons-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/weapons-active.png');}
	}

	&--fire {
		background-image: url('../../assets/images/badges/fire-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/fire-active.png');}
	}
	&--gatherer {
		background-image: url('../../assets/images/badges/gatherer-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/gatherer-active.png');}
	}

	&--tracks {
		margin-left: 2.5em;
		background-image: url('../../assets/images/badges/tracks-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/tracks-active.png');}
	}
	&--hunting {
		margin-right: 2.5em;
		background-image: url('../../assets/images/badges/hunting-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/hunting-active.png');}
	}

	&--animals {
		margin-bottom: 2.5em;
		background-image: url('../../assets/images/badges/animals-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/animals-active.png');}
	}
	&--magic {
		margin: 0 4em;
		background-image: url('../../assets/images/badges/magic-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/magic-active.png');}
	}
	&--prey {
		margin-bottom: 2.5em;
		background-image: url('../../assets/images/badges/prey-inactive.png');
		&.Badge--selected {background-image: url('../../assets/images/badges/prey-active.png');}
	}	
}

.Badge.Badge--overview {
	position: absolute;
	width: 5em;
	height: 5em;
	margin: 0;
	left: auto;
	right: auto;
	&.Badge--selected {@include no-animation();}
	&.Badge--cave,
	&.Badge--weapons,
	&.Badge--animals {
		bottom: 1.5em;
		left: 1.5em;
	}
	&.Badge--fire,
	&.Badge--gatherer,
	&.Badge--magic {
		bottom: 0em;
		left: calc(50% - 2.5em);
	}
	&.Badge--tracks,
	&.Badge--hunting,
	&.Badge--prey {
		bottom: 1.5em;
		right: 1.5em;
	}
}