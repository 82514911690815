@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.BadgeButton {
	position: relative;
	cursor: pointer;
	&:hover {@include scale(1.01);}
	&--activityDescription {
		display: inline-block;
		vertical-align: top;
		margin: 0 0.5em;
	}
}

.BadgeButton-icon {
	width: 9em;
	height: 9em;
	border-radius: 9em;
	background-color: transparent;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center center;

	&--cave {
		background-image: url('../../assets/images/badges/cave-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/cave-active.png');
		}
	}
	&--fire {
		background-image: url('../../assets/images/badges/fire-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/fire-active.png');
		}
	}
	&--tracks {
		background-image: url('../../assets/images/badges/tracks-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/tracks-active.png');
		}
	}
	&--weapons {
		background-image: url('../../assets/images/badges/weapons-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/weapons-active.png');
		}
	}
	&--gatherer {
		background-image: url('../../assets/images/badges/gatherer-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/gatherer-active.png');
		}
	}
	&--hunting {
		background-image: url('../../assets/images/badges/hunting-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/hunting-active.png');
		}
	}
	&--animals {
		background-image: url('../../assets/images/badges/animals-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/animals-active.png');
		}
	}
	&--magic {
		background-image: url('../../assets/images/badges/magic-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/magic-active.png');
		}
	}
	&--prey {
		background-image: url('../../assets/images/badges/prey-inactive.png');
		&.BadgeButton-icon--selected {
			background-image: url('../../assets/images/badges/prey-active.png');
		}
	}
}