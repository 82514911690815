@import '../styles/fonts';
@import '../styles/variables';
@import '../styles/mixins';
@import '../styles/animations';
.App {
	text-align: center;
	height: 100%;
	min-height:100%;
	width: 100%;
	overflow: hidden;
}