@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.Story {
	height: 100%;
	width: 100%;
	background-color: $beige;
	overflow:hidden;
	user-select: none;
	.BackButton {
		z-index: 10;
	}
	&--configure {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
}

.Story-header {
	text-align: left;
	height: 14em;
	padding-top: 4em;
}
.Story-info {
	line-height: 2em;
	padding-left: 4em;
	font-size: 2em;
	font-weight: bold;
}
.Story-title {
	font-family: Antropos;
	font-size: 4em;
	padding-left: 2em;
	color: $green;
}

.Story-body {
	height: calc(100% - 14em);
	width: 100%;
	overflow-y: auto;
	&::-webkit-scrollbar {display:none;}
}


.Story-picture {
	position: relative;
	width: 100%;
	height: 50em;
	background-repeat: no-repeat;
	background-size: 100% auto;
	background-position: center center;
	background-color: black;
	&--build-huts {background-image: url('../../assets/images/animations/build-huts/thumb.jpg');}
	&--gather-magic {background-image: url('../../assets/images/animations/gather-magic/thumb.jpg');}
	&--light-a-fire {background-image: url('../../assets/images/animations/light-a-fire/thumb.jpg');}
	&--make-a-weapon {background-image: url('../../assets/images/animations/make-a-weapon/thumb.jpg');}
	&--draw-magic-symbols {background-image: url('../../assets/images/animations/draw-magic-symbols/thumb.jpg');}
	&--find-animal-tracks {background-image: url('../../assets/images/animations/find-animal-tracks/thumb.jpg');}
	&--music-and-spirits {background-image: url('../../assets/images/animations/music-and-spirits/thumb.jpg');}
	&--taste-stoneage-food {
		background-position: center top;
		background-image: url('../../assets/images/animations/taste-stoneage-food/thumb.jpg');
	}
	&--move-like-a-hunter {
		background-position: center top;
		background-image: url('../../assets/images/animations/move-like-a-hunter/thumb.jpg');
	}
}

.Story-text {
	text-align: left;
	padding: 2em 4em 0 8em;
	p {
		font-size: 1.875em;
		line-height: 1.67;
		margin-top: 0;
		padding-right: 5em;
	}
}

.Story-audio {
	position: absolute;
	@include opacity(1);
	@include transition(opacity, 0.5, 'ease');
	&--hidden {@include opacity(0);}
	&--text {
		top: 0;
		right: 0;
	}
	&--animation {
		z-index: 8;
		bottom: 3em;
		left: 0;
		width: 100%;
		text-align: center;
	}
}

.Story-animation {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $beige;
	overflow:hidden;
	user-select: none;
	background-image: url('../../assets/images/characters-ask-and-liv.png');
	background-repeat: no-repeat;
	background-size: 50% auto;
	background-position: center center;
	z-index: 5;
}