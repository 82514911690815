@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.GatherMagic {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/gather-magic/background.jpg');
	background-position: top right;
	background-size: cover ;
	background-repeat: no-repeat;
}

.GatherMagic-hare,
.GatherMagic-hare-ear1,
.GatherMagic-hare-ear2,
.GatherMagic-bird1,
.GatherMagic-bird2,
.GatherMagic-sunbeam,
.GatherMagic-askEyes,
.GatherMagic-livEyes {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: top right;
	background-size: cover;
	background-repeat: no-repeat;
}

.GatherMagic-hare {
	background-image: url('../../../assets/images/animations/gather-magic/hare-body.png');
}

.GatherMagic-hare-ear1 {
	background-image: url('../../../assets/images/animations/gather-magic/hare-ear1.png');
	@include translateAndRotate(0, 0, 0, 0deg);
	-webkit-animation: hare-ear1 20s linear 0s infinite;
	animation: hare-ear1 20s linear 0s infinite;
}

.GatherMagic-hare-ear2 {
	background-image: url('../../../assets/images/animations/gather-magic/hare-ear2.png');
	@include translateAndRotate(0, 0, 0, 0deg);
	-webkit-animation: hare-ear2 20s linear 0s infinite;
	animation: hare-ear2 20s linear 0s infinite;
}

.GatherMagic-bird1 {
	background-image: url('../../../assets/images/animations/gather-magic/bird1.png');
	@include translateAndRotate(0, 0, 0, 0deg);
	-webkit-animation: bird1 18s linear 5s infinite;
	animation: bird1 18s linear 5s infinite;
}

.GatherMagic-bird2 {
	background-image: url('../../../assets/images/animations/gather-magic/bird2.png');
	@include translateAndRotate(0, 0, 0, 0deg);
	-webkit-animation: bird2 17s linear 0s infinite;
	animation: bird2 17s linear 0s infinite;
}

.GatherMagic-askEyes {
	@include opacity(0);
	@include translateAndRotate(0, 0, 0, 0deg);
	background-image: url('../../../assets/images/animations/gather-magic/ask-eyes.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}

.GatherMagic-livEyes {
	@include opacity(0);
	@include translateAndRotate(0, 0, 0, 0deg);
	background-image: url('../../../assets/images/animations/gather-magic/liv-eyes.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}

.GatherMagic-grass1,
.GatherMagic-grass2,
.GatherMagic-grass3,
.GatherMagic-foreground {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: bottom left;
	background-size: cover;
	background-repeat: no-repeat;
}

.GatherMagic-foreground {
	background-image: url('../../../assets/images/animations/gather-magic/foreground.png');
}

.GatherMagic-grass1 {
	background-image: url('../../../assets/images/animations/gather-magic/grass1.png');
	-webkit-animation: grass1 25s ease-in-out 1s infinite;
	animation: grass1 25s ease-in-out 1s infinite;
	@include translateAndRotate(0, 0, 0, 5deg);
}
.GatherMagic-grass2 {
	background-image: url('../../../assets/images/animations/gather-magic/grass2.png');
	-webkit-animation: grass2 25s ease-in-out 0s infinite;
	animation: grass2 25s ease-in-out 0s infinite;
	@include translate3d(0, 0, 0,);
}
.GatherMagic-grass3 {
	background-image: url('../../../assets/images/animations/gather-magic/grass3.png');
	-webkit-animation: grass3 25s ease-in-out 0.5s infinite;
	animation: grass3 25s ease-in-out 0.5s infinite;
	@include translateAndRotate(0, 0, 0, 10deg);
}

.GatherMagic-sunbeam {
	@include translate3d(0, 0, 0,);
	&--1, &--2, &--3, &--4, &--5, &--6 {top: -2em;}
	&--1 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur5.png');}
	&--2 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur3.png');}
	&--3 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur1.png');}
	&--4 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur2.png');}
	&--5 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur1.png');}
	&--6 {background-image: url('../../../assets/images/animations/shared/sunbeams2-blur4.png');}

	&--1 {
		@include opacity(1);
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		left: 2em;
	}
	&--2 {
		@include opacity(0);
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 5s infinite;
		left: -2em;
	}
	&--3 {
		@include opacity(0);
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		left: -5em;
	}
	&--4 {
		@include opacity(1);
		left: 4em;
		-webkit-animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
		animation: sunbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
	}
	&--5 {
		@include opacity(0);
		left: -3em;
		-webkit-animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 2s infinite;
		animation: sunbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 5s infinite;

	}
	&--6 {
		@include opacity(0);
		left: 0em;
		-webkit-animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
		animation: sunbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
	}
}

@-webkit-keyframes hare-ear1 {
	0% {@include rotate(0deg);}
	14.999% {@include rotate(0deg);}
	15% {@include rotate(10deg);}
	15.5% {@include rotate(-5deg);}
	16% {@include rotate(0deg);}
	29.999% {@include rotate(0deg);}
	30% {@include rotate(10deg);}
	30.5% {@include rotate(-5deg);}
	31% {@include rotate(0deg);}
	64.999% {@include rotate(0deg);}
	65% {@include rotate(10deg);}
	65.5% {@include rotate(-5deg);}
	66% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes hare-ear1 {
	0% {@include rotate(0deg);}
	14.999% {@include rotate(0deg);}
	15% {@include rotate(10deg);}
	15.5% {@include rotate(-5deg);}
	16% {@include rotate(0deg);}
	29.999% {@include rotate(0deg);}
	30% {@include rotate(10deg);}
	30.5% {@include rotate(-5deg);}
	31% {@include rotate(0deg);}
	64.999% {@include rotate(0deg);}
	65% {@include rotate(10deg);}
	65.5% {@include rotate(-5deg);}
	66% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}

@-webkit-keyframes hare-ear2 {
	0% {@include rotate(0deg);}
	29.999% {@include rotate(0deg);}
	30% {@include rotate(-10deg);}
	30.5% {@include rotate(5deg);}
	31% {@include rotate(0deg);}
	59.999% {@include rotate(0deg);}
	60% {@include rotate(-5deg);}
	60.5% {@include rotate(5deg);}
	61% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}
@keyframes hare-ear2 {
	0% {@include rotate(0deg);}
	29.999% {@include rotate(0deg);}
	30% {@include rotate(-10deg);}
	30.5% {@include rotate(5deg);}
	31% {@include rotate(0deg);}
	59.999% {@include rotate(0deg);}
	60% {@include rotate(-5deg);}
	60.5% {@include rotate(5deg);}
	61% {@include rotate(0deg);}
	100% {@include rotate(0deg);}
}