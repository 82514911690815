@import '../../styles/fonts';
@import '../../styles/variables';
@import '../../styles/mixins';
@import '../../styles/animations';
.ZoomButton {
	position: absolute;
	right: 1em;
	bottom: 1em;
	cursor: pointer;
	&:hover {@include scale(1.01);}
}

.ZoomButton-icon {
	width: 9em;
	height: 9em;
	border-radius: 9em;
	background-color: white;
	background-image: url('../../assets/images/icon-zoom.svg');
	background-repeat: no-repeat;
	background-size: 4em auto;
	background-position: center center;
	border: 0.25em solid $green;
}