@import '../../../styles/fonts';
@import '../../../styles/variables';
@import '../../../styles/mixins';
@import '../../../styles/animations';
@import 'animations';

.DrawMagicSymbols {
	position: relative;
	width: 100%;
	height: 100%;
	background-image: url('../../../assets/images/animations/draw-magic-symbols/background.jpg');
	background-position: top left;
	background-size: cover ;
	background-repeat: no-repeat;
}

.DrawMagicSymbols-moonbeam,
.DrawMagicSymbols-askEyes,
.DrawMagicSymbols-livEyes,
.DrawMagicSymbols-livEyes2,
.DrawMagicSymbols-livArms,
.DrawMagicSymbols-swing,
.DrawMagicSymbols-foreground,
.DrawMagicSymbols-amulet {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: top left;
	background-size: cover;
	background-repeat: no-repeat;
}

.DrawMagicSymbols-askEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/ask-eyes.png');
	-webkit-animation: eye-blink 10s linear 1s infinite;
	animation: eye-blink 10s linear 1s infinite;
}

.DrawMagicSymbols-livEyes {
	@include opacity(0);
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/liv-eye.png');
	-webkit-animation: eye-blink 10s linear 3s infinite;
	animation: eye-blink 10s linear 3s infinite;
}

.DrawMagicSymbols-livArms {
	@include translate3d(0, 0, 0);
	&--1 {
		@include opacity(1);
		background-image: url('../../../assets/images/animations/draw-magic-symbols/liv-arms1.png');
		-webkit-animation: liv-praying-1 8s linear 3s infinite;
		animation: liv-praying-1 8s linear 3s infinite;
	}
	&--2 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/draw-magic-symbols/liv-arms2.png');
		-webkit-animation: liv-praying-2 8s linear 3s infinite;
		animation: liv-praying-2 8s linear 3s infinite;
	}
	&--3 {
		@include opacity(0);
		background-image: url('../../../assets/images/animations/draw-magic-symbols/liv-arms3.png');
		-webkit-animation: liv-praying-3 8s linear 3s infinite;
		animation: liv-praying-3 8s linear 3s infinite;
	}
}
.DrawMagicSymbols-livEyes2 {
	@include translate3d(0, 0, 0);
	@include opacity(0);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/liv-eye.png');
	-webkit-animation: liv-praying-eyes 8s linear 3s infinite;
	animation: liv-praying-eyes 8s linear 3s infinite;
}

.DrawMagicSymbols-swing {
	@include translate3d(0, 0, 0);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/swing.png');
	-webkit-animation: ask-swinging .75s linear 0s infinite;
	animation: ask-swinging .75s linear 0s infinite;
}

.DrawMagicSymbols-foreground {
	background-image: url('../../../assets/images/animations/draw-magic-symbols/foreground.png');
}

.DrawMagicSymbols-amulet {
	background-image: url('../../../assets/images/animations/draw-magic-symbols/amulet.png');
}

.DrawMagicSymbols-grass1,
.DrawMagicSymbols-grass2,
.DrawMagicSymbols-grass3 {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	background-position: bottom left;
	background-size: cover;
	background-repeat: no-repeat;
}

.DrawMagicSymbols-grass1 {
	@include translateAndRotate(0, 0, 0, 5deg);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/grass1.png');
	-webkit-animation: grass1 25s ease-in-out 1s infinite;
	animation: grass1 25s ease-in-out 1s infinite;
	
}
.DrawMagicSymbols-grass2 {
	@include translateAndRotate(0, 0, 0, 0deg);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/grass2.png');
	-webkit-animation: grass2 25s ease-in-out 0s infinite;
	animation: grass2 25s ease-in-out 0s infinite;
}
.DrawMagicSymbols-grass3 {
	@include translateAndRotate(0, 0, 0, 10deg);
	background-image: url('../../../assets/images/animations/draw-magic-symbols/grass3.png');
	-webkit-animation: grass3 25s ease-in-out 0.5s infinite;
	animation: grass3 25s ease-in-out 0.5s infinite;
}

.DrawMagicSymbols-moonbeam {
	top: -20%;
	@include translate3d(0, 0, 0,);
	&--1, &--2, &--3, &--4, &--5, &--6 {top: -2em;}
	&--1 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur5.png');}
	&--2 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur3.png');}
	&--3 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur1.png');}
	&--4 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur2.png');}
	&--5 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur1.png');}
	&--6 {background-image: url('../../../assets/images/animations/shared/moonbeams-blur4.png');}
	
	&--1 {
		@include opacity(0.5);
		-webkit-animation: moonbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		animation: moonbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate 17s ease-in-out 3s infinite;
		left: 2em;
	}
	&--2 {
		@include opacity(0);
		-webkit-animation: moonbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 2s infinite;
		animation: moonbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate 15s ease-in-out 5s infinite;
		left: -2em;
	}
	&--3 {
		@include opacity(0);
		-webkit-animation: moonbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		animation: moonbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate 13s ease-in-out 0s infinite;
		left: -5em;
	}
	&--4 {
		@include opacity(0.5);
		left: 4em;
		-webkit-animation: moonbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
		animation: moonbeam-1 11s ease-in-out 0s infinite, sunbeamTranslate2 11s ease-in-out 3s infinite;
	}
	&--5 {
		@include opacity(0);
		left: -3em;
		-webkit-animation: moonbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 2s infinite;
		animation: moonbeam-2 11s ease-in-out 0s infinite, sunbeamTranslate2 7s ease-in-out 5s infinite;
	
	}
	&--6 {
		@include opacity(0);
		left: 0em;
		-webkit-animation: moonbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
		animation: moonbeam-3 11s ease-in-out 0s infinite, sunbeamTranslate2 13s ease-in-out 0s infinite;
	}
}
@-webkit-keyframes liv-praying-1 {
	0% {@include opacity(1);}
	4.999% {@include opacity(1);}
	5% {@include opacity(0);}
	19.999% {@include opacity(0);}
	20% {@include opacity(1);}
	100% {@include opacity(1);}
}
@keyframes liv-praying-1 {
	0% {@include opacity(1);}
	4.999% {@include opacity(1);}
	5% {@include opacity(0);}
	19.999% {@include opacity(0);}
	20% {@include opacity(1);}
	100% {@include opacity(1);}
}

@-webkit-keyframes liv-praying-2 {
	0% {@include opacity(0);}
	4.999% {@include opacity(0);}
	5% {@include opacity(1);}
	9.999% {@include opacity(1);}
	10% {@include opacity(0);}
	14.999% {@include opacity(0);}
	15% {@include opacity(1);}
	19.999% {@include opacity(1);}
	20% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes liv-praying-2 {
	0% {@include opacity(0);}
	4.999% {@include opacity(0);}
	5% {@include opacity(1);}
	9.999% {@include opacity(1);}
	10% {@include opacity(0);}
	14.999% {@include opacity(0);}
	15% {@include opacity(1);}
	19.999% {@include opacity(1);}
	20% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes liv-praying-3 {
	0% {@include opacity(0);}
	9.999% {@include opacity(0);}
	10% {@include opacity(1);}
	14.999% {@include opacity(1);}
	15% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes liv-praying-3 {
	0% {@include opacity(0);}
	9.999% {@include opacity(0);}
	10% {@include opacity(1);}
	14.999% {@include opacity(1);}
	15% {@include opacity(0);}
	100% {@include opacity(0);}
}

@-webkit-keyframes liv-praying-eyes {
	0% {@include opacity(0);}
	3.999% {@include opacity(0);}
	4% {@include opacity(1);}
	23.999% {@include opacity(1);}
	24% {@include opacity(0);}
	100% {@include opacity(0);}
}
@keyframes liv-praying-eyes {
	0% {@include opacity(0);}
	3.999% {@include opacity(0);}
	4% {@include opacity(1);}
	23.999% {@include opacity(1);}
	24% {@include opacity(0);}
	100% {@include opacity(0);}
}


@-webkit-keyframes ask-swinging {
	0% {@include translate3d(0, 0, 0);}
	15% {@include translate3d(.5em, 0, 0);}
	20% {@include translate3d(0, 0, 0);}
	35% {@include translate3d(-.5em, 0, 0);}
	50% {@include translate3d(0, 0, 0);}
	100% {@include translate3d(0, 0, 0);}
}
@keyframes ask-swinging {
	0% {@include translate3d(0, 0, 0);}
	15% {@include translate3d(.5em, 0, 0);}
	20% {@include translate3d(0, 0, 0);}
	35% {@include translate3d(-.5em, 0, 0);}
	50% {@include translate3d(0, 0, 0);}
	100% {@include translate3d(0, 0, 0);}
}

@-webkit-keyframes moonShadow {
	0% {@include opacity(0.7);}
	20% {@include opacity(0.6);}
	40% {@include opacity(0.7);}
	60% {@include opacity(0.6);}
	100% {@include opacity(0.7);}
}
@keyframes moonShadow {
	0% {@include opacity(0.7);}
	20% {@include opacity(0.6);}
	40% {@include opacity(0.7);}
	60% {@include opacity(0.6);}
	100% {@include opacity(0.7);}
}